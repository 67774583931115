import React from 'react';
import Button from '~/components/Button';

const HeaderRow = ({
  toggleCollapse,
  isFullyCollapsed,
  leadingColumnWidths,
}: {
  toggleCollapse: () => void;
  isFullyCollapsed: boolean;
  leadingColumnWidths: [number, number, number];
}): React.ReactNode => {
  return (
    <div className="flex w-full border-b bg-white border-neutral-50 pl-4 pt-2">
      <div className="flex" style={{ width: leadingColumnWidths.reduce((a, b) => a + b, 0) }}>
        <Button fill="clear" className="!w-auto !px-2" onClick={toggleCollapse}>
          {isFullyCollapsed ? 'Open All' : 'Collapse All'}
        </Button>
      </div>
    </div>
  );
};

export default HeaderRow;
