import React, { useEffect } from 'react';
import { toZonedTime } from 'date-fns-tz';
import { addMonths, format, isSameMonth } from 'date-fns';
import date from '~/utils/dates/date';
import PeriodPicker from '~/components/PeriodPicker';
import Typography from '~/components/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import usePeriodPicker from '../PeriodPicker/usePeriodPicker';
import request from '~/utils/request';
import { StatusCodes } from 'http-status-codes';
import { setPreferences } from '~/store/userSlice';

const UserDateRange = ({ pickerAlignment = 'left' }: { pickerAlignment?: 'left' | 'right' }): React.ReactElement => {
  const dispatch = useDispatch();
  const { preferences, uuid } = useSelector((state: State) => state.user);
  const companyStartDate = useSelector((state: State) => state.organization.configuration.companyStartDate);

  const getInitialDate = (defaultDate: string | null): Date | null => {
    if (defaultDate) {
      return new Date(toZonedTime(defaultDate, 'UTC'));
    }

    return null;
  };

  const [startDate, setStartDate] = usePeriodPicker({
    startDate: getInitialDate(preferences.defaultGraphStartDate),
    endDate: date(),
    mode: 'month',
  });

  const [endDate, setEndDate] = usePeriodPicker({
    startDate: getInitialDate(preferences.defaultGraphEndDate),
    endDate: date(),
    mode: 'month',
  });

  useEffect(() => {
    const updateDates = async (): Promise<void> => {
      const formattedStartDate = format(startDate.startDate ?? new Date(), 'yyyy-MM-dd');
      const formattedEndDate = format(endDate.startDate ?? new Date(), 'yyyy-MM-dd');

      if (
        (!isSameMonth(formattedStartDate, preferences.defaultGraphStartDate) ||
          !isSameMonth(formattedEndDate, preferences.defaultGraphEndDate)) &&
        uuid
      ) {
        const response = await request({
          url: `/users/${uuid}/preferences`,
          method: 'PATCH',
          body: {
            defaultGraphStartDate: formattedStartDate,
            defaultGraphEndDate: formattedEndDate,
          },
        });

        if (response.status === StatusCodes.OK) {
          dispatch(
            setPreferences({
              ...preferences,
              defaultGraphStartDate: formattedStartDate,
              defaultGraphEndDate: formattedEndDate,
            }),
          );
        }
      }
    };

    updateDates();
  }, [startDate.startDate, endDate.startDate, dispatch]);

  useEffect(() => {
    const formattedStartDate = format(startDate.startDate ?? new Date(), 'yyyy-MM-dd');
    const formattedEndDate = format(endDate.startDate ?? new Date(), 'yyyy-MM-dd');
    if (startDate.startDate && !isSameMonth(preferences.defaultGraphStartDate, formattedStartDate)) {
      setStartDate({
        startDate: getInitialDate(preferences.defaultGraphStartDate),
        endDate: date(),
        mode: 'month',
      });
    }
    if (endDate.startDate && !isSameMonth(preferences.defaultGraphEndDate, formattedEndDate)) {
      setEndDate({
        startDate: getInitialDate(preferences.defaultGraphEndDate),
        endDate: date(),
        mode: 'month',
      });
    }
  }, [preferences.defaultGraphStartDate, preferences.defaultGraphEndDate]);

  return (
    <div className="flex items-center gap-2">
      <PeriodPicker
        id="user-date-range-start-date"
        state={startDate}
        setState={setStartDate}
        border={'solid'}
        beBefore={endDate.startDate}
        minYear={toZonedTime(companyStartDate, 'UTC').getFullYear()}
        maxYear={addMonths(date(), 36).getFullYear()}
        pickerAlignment={pickerAlignment}
      />
      <Typography size="xl" color="empty" className="pb-[1px]">
        -
      </Typography>
      <PeriodPicker
        id="user-date-range-end-date"
        state={endDate}
        setState={setEndDate}
        border={'solid'}
        beAfter={startDate.startDate}
        beBefore={addMonths(date(), 36)}
        minYear={toZonedTime(companyStartDate, 'UTC').getFullYear()}
        maxYear={addMonths(date(), 36).getFullYear()}
        pickerAlignment={pickerAlignment}
      />
    </div>
  );
};

export default UserDateRange;
