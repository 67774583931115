import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import BaseTable from '~/components/Table/Base/BaseTable';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  sortingFns,
  useReactTable,
} from '@tanstack/react-table';
import formatCurrency from '~/utils/formatCurrency';
import { format, isBefore } from 'date-fns';
import { formatEndDateForTable } from '~/pages/Expenses/utils/formatEndDateForTable';
import { ExpensesPageContext } from '~/pages/Expenses/context/ExpensesContext';
import { convertDepartmentUuidsToDisplayName } from '~/pages/Expenses/utils/convertDepartmentUuidsToDisplayName';
import expenseModelEmptyState from '~/assets/expenseModelEmptyState.svg';
import Typography from '~/components/Typography';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import { IExpenseTableRow, IExpense } from '../types';
import formatPercent from '~/utils/formatPercent';
import { sortAmountFn } from '~/pages/Expenses/components/Expenses/table/customSortingFunctions';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { filterExpenses } from './filterExpenses';
import useQueryParams from '~/utils/hooks/useQueryParams';
import request from '~/utils/request';
import { toZonedTime } from 'date-fns-tz';
import { StatusCodes } from 'http-status-codes';
import date from '~/utils/dates/date';

const TYPE_DISPLAY_MAP = {
  setCost: 'Set Cost',
  headcountFixed: 'Amount per Employee',
  headcountPercentCompensation: 'Percent of Salary',
};

const FREQUENCY_DISPLAY_MAP = {
  monthly: 'Monthly',
  oneTime: 'One Time',
  onHire: 'On Hire',
  quarterly: 'Quarterly',
  annually: 'Annually',
};

export const PERCENTAGE_CONVERSION_DENOMINATOR = 10000;

const ExpenseTable = (): ReactElement => {
  const {
    expenses,
    departmentDict,
    searchByName,
    positions,
    lockedDate,
    setDiscontinueExpense,
    setDeleteExpense,
    setExpenseUuid,
    setExpenseModal,
    setExpenses,
    revalidateFilteredExpensesReport,
    showPastExpenses,
  } = useContext(ExpensesPageContext);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [filteredExpenses, setFilteredExpenses] = useState<IExpense[]>([]);
  const [queryParams] = useQueryParams();
  const tagParam = queryParams.get('tagFilter');
  const frequencyParam = queryParams.get('frequencyFilter');

  useEffect(() => {
    setFilteredExpenses(
      filterExpenses({
        expenses,
        search: searchByName,
        lockedDate,
        tagParam,
        frequencyParam,
        positions,
      }),
    );
  }, [expenses, searchByName, lockedDate, queryParams, positions, departmentDict]);

  /**
   * Generate table data
   */
  const { tableData, tableColumns } = useMemo(() => {
    const columns = [
      { id: 'name', label: 'NAME', enableSorting: true },
      { id: 'tag', label: 'CATEGORY', enableSorting: true },
      { id: 'driver', label: 'TYPE', enableSorting: true },
      { id: 'department', label: 'DEPARTMENT', enableSorting: true },
      {
        id: 'amount',
        label: 'AMOUNT',
        enableSorting: true,
        sortingFn: sortAmountFn,
      },
      {
        id: 'frequency',
        label: 'FREQUENCY',
        enableSorting: true,
      },
      { id: 'startDate', label: 'START DATE' },
      { id: 'endDate', label: 'END DATE' },
      { id: 'ellipsisDropdown', label: '' },
    ];

    const columnHelper = createColumnHelper<IExpenseTableRow>();

    const tableColumns = columns.map((col) =>
      columnHelper.accessor(col.id, {
        enableResizing: false,
        enablePinning: false,
        header: () => <div className="text-left">{col.label}</div>,
        cell: (info) => (
          <Typography
            color={info.row.original.isGrayedOut ? 'disabled' : undefined}
            id={`expense-${col.id}-${info.row.index}`}
          >
            {info.row.original[col.id]}
          </Typography>
        ),
        enableSorting: col.enableSorting ?? false,
        sortingFn: col.sortingFn ?? sortingFns.alphanumeric,
      }),
    );

    const handleDeleteExpense = async ({ uuid, expenseUuid }: { uuid: string; expenseUuid: string }): Promise<void> => {
      if (activeScenarioUuid) {
        const response = await request({
          method: 'DELETE',
          url: `/expenses/${expenseUuid}`,
          params: {
            scenarioUuid: activeScenarioUuid,
          },
          headers: {
            'Organization-Uuid': organizationUuid,
          },
        });

        if (response.status === StatusCodes.NO_CONTENT) {
          setExpenses(expenses.filter((expense) => expense.uuid !== uuid));
          setDeleteExpense(undefined);
          revalidateFilteredExpensesReport();
        }
      } else {
        setDeleteExpense({ uuid, expenseUuid });
      }
    };

    const tableData = filteredExpenses
      .filter((expense) => {
        if (showPastExpenses) {
          return true;
        } else if (expense.context.endDate) {
          return !isBefore(expense.context.endDate, date());
        } else {
          return true;
        }
      })
      .map(({ name, context, uuid, expenseUuid, isGrayedOut }, index) => {
        return {
          name,
          tag: context.tag,
          driver: TYPE_DISPLAY_MAP[context.driver],
          department: convertDepartmentUuidsToDisplayName({
            departmentUuids: context.departments,
            departmentDict,
          }),
          amount:
            context.driver === 'headcountPercentCompensation'
              ? formatPercent({
                  value: context.amount / PERCENTAGE_CONVERSION_DENOMINATOR,
                })
              : formatCurrency(context.amount),
          frequency: FREQUENCY_DISPLAY_MAP[context.frequency],
          startDate: format(toZonedTime(context.startDate, 'UTC'), 'MMMM yyyy'),
          endDate: formatEndDateForTable({
            endDate: context.endDate,
            frequency: context.frequency,
          }),
          ellipsisDropdown: (
            <EllipsisDropdown
              id={`expense-ellipsis-dropdown-${uuid}`}
              placeTop={Boolean(
                activeScenarioUuid && filteredExpenses.length > 1 && index === filteredExpenses.length - 1,
              )}
              options={[
                {
                  label: 'Edit',
                  onClick: (): void => {
                    setExpenseUuid({ uuid, expenseUuid, type: 'edit' });
                    setExpenseModal(true);
                  },
                  className: 'max-sm:hidden',
                },
                {
                  label: 'Duplicate',
                  onClick: (): void => {
                    setExpenseUuid({ uuid, expenseUuid, type: 'create' });
                    setExpenseModal(true);
                  },
                  className: 'max-sm:hidden',
                },
                ...(context.frequency !== 'oneTime'
                  ? [
                      {
                        label: 'Discontinue',
                        onClick: () =>
                          setDiscontinueExpense({
                            uuid,
                            expenseUuid,
                            startDate: context.startDate,
                          }),
                        className: 'max-sm:hidden',
                      },
                    ]
                  : []),
                {
                  label: 'Delete',
                  onClick: () => handleDeleteExpense({ uuid, expenseUuid }),
                  className: 'text-red-500 max-sm:hidden',
                },
              ]}
            />
          ),
          isGrayedOut,
        };
      });

    return { tableData, tableColumns };
  }, [filteredExpenses, setDiscontinueExpense, setDeleteExpense, showPastExpenses]);

  const table = useReactTable({
    columns: tableColumns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const tableEmptyState = (
    <div className="flex flex-col items-center gap-2 py-16">
      <img src={expenseModelEmptyState} alt="Empty Tasks Illustration" className="w-64 h-auto" />
      <div className="flex flex-col items-center gap-4 max-w-[512px] text-center">
        <Typography color="empty" size="sm">
          This will assist your organization in managing costs effectively, highlight the total cost of headcount, and
          facilitate informed decision-making regarding your financial resources.
        </Typography>
        <Typography color="empty" size="sm">
          Click Add Expense to get started.
        </Typography>
      </div>
    </div>
  );

  const noResultsEmptyState = (
    <div className="flex flex-col items-center gap-2 py-16">
      <img src={expenseModelEmptyState} alt="Empty Tasks Illustration" className="w-64 h-auto" />
      <div className="flex flex-col items-center gap-4 max-w-[512px] text-center">
        <Typography color="empty" size="sm">
          No Expenses
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      {expenses.length ? (
        <div>
          <div className="max-w-full w-full overflow-scroll hide-scrollbar px-10">
            {filteredExpenses.length ? (
              <BaseTable
                id="expenses-table"
                table={table}
                styles={{
                  table: 'w-full',
                  th: 'px-4 py-2 text-xs font-normal text-neutral-200 text-nowrap',
                  td: 'h-14 px-4 border-t border-b border-gray-200 text-nowrap',
                }}
              />
            ) : (
              noResultsEmptyState
            )}
          </div>
        </div>
      ) : (
        tableEmptyState
      )}
    </>
  );
};

export default ExpenseTable;
