import React, { Fragment, useContext, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import Typography from '~/components/Typography';
import { ChevronDownIcon, ChevronUpIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { IScenario } from '~/pages/Dashboard/entity/types';
import Divider from '~/components/Divider';
import request from '~/utils/request';
import toast from 'react-hot-toast';
import Button from '~/components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '~/store';
import { addScenarioUuid, removeScenarioUuid, update, updateScenarioLoadingState } from '~/store/scenarioSlice';
import { DashboardPageContext } from '../../../context/DashboardContext';
import ScenarioItem from './ScenarioItem';
import { StatusCodes } from 'http-status-codes';
import { fetchScenarios } from '~/pages/Dashboard/utils/fetchScenarios';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import HoverPopover from '~/components/HoverPopover';
import NameScenarioModal from '~/components/ScenarioMode/components/NameScenarioModal';
import { useInput } from '~/components/Input';
import { datadogRum } from '@datadog/browser-rum';

interface IGetScenarioResponse {
  status: number;
  data: {
    data: IScenario;
  };
}

const ScenarioMenuDropdown = ({
  scenarios,
  createScenario,
}: {
  scenarios: IScenario[];
  createScenario?: (type: 'dynamic' | 'static', title?: string) => Promise<void>;
}): React.ReactElement => {
  const { reload, setScenarios } = useContext(DashboardPageContext);
  const dispatch = useDispatch();
  const { activeScenarioUuid, selectedScenarioUuids } = useSelector((state: State) => state.scenario);
  const user = useSelector((state: State) => state.user);
  const [showNameScenarioModal, setShowNameScenarioModal] = useState<boolean>(false);
  const [staticScenarioTitle, setStaticScenarioTitle] = useInput({
    value: '',
    valid: true,
    validation: /.*/,
  });

  const dynamicScenarios = scenarios.filter((scenario) => scenario.type !== 'static');
  const staticScenarios = scenarios.filter((scenario) => scenario.type === 'static');

  const handleSelectScenario = async (scenario: IScenario, close?: () => void): Promise<void> => {
    const isAlreadySelected = selectedScenarioUuids.includes(scenario.uuid);

    if (isAlreadySelected) {
      dispatch(removeScenarioUuid(scenario.uuid));
    } else {
      dispatch(addScenarioUuid(scenario.uuid));
      dispatch(updateScenarioLoadingState('viewing'));
      const refreshResponse = await request({
        method: 'POST',
        headers: { 'Organization-Uuid': scenario.organizationUuid },
        params: { scenarioUuid: scenario.uuid },
        url: `/formulas/refresh`,
      });
      dispatch(updateScenarioLoadingState('idle'));
      if (refreshResponse.status !== StatusCodes.OK) {
        dispatch(removeScenarioUuid(scenario.uuid));
        toast.error('Failed to select scenario');
        return;
      }
    }

    if (close) close();
  };

  const handleDeleteScenario = async (scenario: IScenario): Promise<void> => {
    const response = await request({
      method: 'DELETE',
      url: `/organizations/${scenario.organizationUuid}/scenarios/${scenario.uuid}`,
    });

    if (response.status === 204) {
      if (selectedScenarioUuids.includes(scenario.uuid)) {
        handleSelectScenario(scenario);
      }
      const scenarios = await fetchScenarios({
        organizationUuid: scenario.organizationUuid,
      });
      setScenarios(scenarios);
      toast.success('Scenario Deleted');
    }
  };

  const handleEditScenario = async (scenario: IScenario): Promise<void> => {
    dispatch(updateScenarioLoadingState('entering'));
    const response: IGetScenarioResponse = await request({
      method: 'GET',
      url: `/organizations/${scenario.organizationUuid}/scenarios/${scenario.uuid}`,
      body: {
        scenarioUuidToDuplicate: scenario.uuid,
        type: 'dynamic',
      },
    });
    if (response.status !== 200) return;
    const filteredSelectedScenarioUuids = selectedScenarioUuids.filter((uuid) => uuid !== scenario.uuid);

    dispatch(
      update({
        activeScenarioUuid: response.data.data.uuid,
        activeScenarioData: response.data.data,
        scenarioLoadingState: 'idle',
        scenarioMode: 'editing',
        activeScenarioHasChanges: false,
        leverChanges: [],
        cashBalanceLockedIndexes: [],
        cashBalanceSelectedPoint: null,
        selectedScenarioUuids: filteredSelectedScenarioUuids,
      }),
    );

    reload();

    if (user.role === 'user') {
      datadogRum.addAction('editScenario', {
        scenarioUuid: scenario.uuid,
        userUuid: user.uuid,
        organizationUuid: scenario.organizationUuid,
      });
    }
  };

  const handleCopyScenario = async (scenario: IScenario): Promise<void> => {
    const response = await request({
      method: 'POST',
      url: `/organizations/${scenario.organizationUuid}/scenarios`,
      body: {
        type: 'dynamic',
      },
      params: {
        scenarioUuidToDuplicate: scenario.uuid,
      },
    });
    if (response.status !== StatusCodes.CREATED) {
      toast.error('Failed to copy scenario');
    } else {
      const scenarios = await fetchScenarios({
        organizationUuid: scenario.organizationUuid,
      });
      setScenarios(scenarios);
      toast.success('Scenario Copied');
    }
  };

  const propagateClick = (): void => {
    document.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }));
  };

  const dynamicScenarioOptions = dynamicScenarios.map((scenario, index) => (
    <ScenarioItem
      scenario={scenario}
      key={scenario.uuid}
      onSelect={handleSelectScenario}
      onEdit={handleEditScenario}
      onDelete={handleDeleteScenario}
      onCopy={handleCopyScenario}
      anchorDirection={index === dynamicScenarios.length - 1 ? 'bottom' : 'top'}
    />
  ));

  const staticScenarioOptions = staticScenarios.map((scenario, index) => (
    <ScenarioItem
      scenario={scenario}
      key={scenario.uuid}
      onSelect={handleSelectScenario}
      onEdit={handleEditScenario}
      onDelete={handleDeleteScenario}
      onCopy={handleCopyScenario}
      anchorDirection={index === staticScenarios.length - 1 ? 'bottom' : 'top'}
    />
  ));

  return (
    <>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              id="scenario-dropdown-button"
              data-testid="scenario-dropdown-button"
              onClick={propagateClick}
              className="focus:outline-none focus:ring-0 focus:border-transparent"
            >
              <div className="flex text-green-400 hover:text-green-500 !w-fit !py-2 !pl-0 !pr-1 gap-1 items-center justify-center">
                Scenarios & Plans
                {open ? (
                  <ChevronUpIcon width={16} height={16} strokeWidth={2.125} />
                ) : (
                  <ChevronDownIcon width={16} height={16} strokeWidth={2.125} />
                )}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={`absolute z-10 transform left-0 mt-1 above shadow-lg`}>
                <div
                  className={`flex flex-col lg:flex-row bg-white overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5`}
                >
                  <div className={`flex-grow py-2 flex flex-col min-h-[100px] lg:w-[370px]`}>
                    <div className="flex items-center justify-between pt-2 px-7 w-full">
                      <div className="flex items-center gap-1">
                        <Typography size="sm" weight="bold">
                          Scenarios
                        </Typography>
                        <HoverPopover
                          buttonContent={<InformationCircleIcon className="size-5 text-gray-500 pointer-events-auto" />}
                          panelContent={
                            <div className="bg-black text-white w-[259px] flex flex-col gap-2 px-5 py-3 rounded-lg text-left">
                              <Typography color="white" size="xs">
                                {`Scenarios allow you visualize the financial impact of business decisions without altering your base model`}
                              </Typography>
                            </div>
                          }
                          anchor="top"
                          panelClassName="z-[100] shadow rounded-lg"
                        />
                      </div>
                      <Button
                        className="flex items-center justify-end gap-1 cursor-pointer !w-fit !p-0 max-sm:hidden stroke-blue-400 hover:stroke-blue-500 disabled:stroke-neutral-100"
                        onClick={async () => {
                          close();
                          if (createScenario) {
                            await createScenario('dynamic');
                          }
                        }}
                        fill="clearBlue"
                        disabled={!!activeScenarioUuid}
                        id="new-scenario-button"
                      >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2 13H11" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M1.99998 11C6.95787 11 10 8 12 6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeDasharray="3 3"
                          />
                          <circle cx="14" cy="3" r="1" strokeLinecap="round" strokeLinejoin="round" />
                          <circle cx="14" cy="13" r="1" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        New Scenario
                      </Button>
                    </div>
                    <Divider orientation="horizontal" className="mt-3 px-7 mb-2" />
                    {dynamicScenarioOptions.length ? (
                      <div
                        className={`flex-grow max-h-[250px] px-3 ${dynamicScenarios.length > 4 && 'overflow-y-scroll'} overflow-x-clip`}
                      >
                        {dynamicScenarioOptions}
                      </div>
                    ) : (
                      <div className="flex-grow flex items-center justify-center">
                        <Typography color="secondary" size="xs" className="text-center italic">
                          Create a scenario to get started
                        </Typography>
                      </div>
                    )}
                  </div>
                  <>
                    <div className="w-px my-3 bg-gray-200"></div>
                    <div className={`flex-grow py-2 flex flex-col min-h-[100px] lg:w-[370px]`}>
                      <div className="flex items-center justify-between pt-2 px-7 w-full">
                        <div className="flex items-center gap-1">
                          <Typography size="sm" weight="bold">
                            Locked Plans
                          </Typography>
                          <HoverPopover
                            buttonContent={
                              <InformationCircleIcon className="size-5 text-gray-500 pointer-events-auto" />
                            }
                            panelContent={
                              <div className="bg-black text-white w-[259px] flex flex-col gap-2 px-5 py-3 rounded-lg text-left">
                                <Typography color="white" size="xs">
                                  {`Locked plans enables you to compare its projections with actual performance results`}
                                </Typography>
                              </div>
                            }
                            anchor="top"
                            panelClassName="z-[100] shadow rounded-lg"
                          />
                        </div>
                        <Button
                          className="flex items-center justify-end gap-1 cursor-pointer font-medium !w-fit !p-0 max-sm:hidden"
                          onClick={() => {
                            setShowNameScenarioModal(true);
                          }}
                          fill="clear"
                          disabled={!!activeScenarioUuid}
                          id="lock-plan-button"
                        >
                          <LockClosedIcon className="size-4" />
                          New Locked Plan
                        </Button>
                      </div>
                      <Divider orientation="horizontal" className="mt-3 px-7 mb-2" />
                      {staticScenarioOptions.length ? (
                        <div
                          className={`flex-grow max-h-[250px] px-3 ${staticScenarios.length > 4 && 'overflow-y-scroll'}`}
                        >
                          {staticScenarioOptions}
                        </div>
                      ) : (
                        <div className="flex-grow flex items-center justify-center">
                          <Typography color="secondary" size="xs" className="text-center italic">
                            Lock a plan to get started
                          </Typography>
                        </div>
                      )}
                    </div>
                  </>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <NameScenarioModal
        isOpen={showNameScenarioModal}
        cancel={() => {
          close();
          setShowNameScenarioModal(false);
        }}
        confirm={() => {
          setShowNameScenarioModal(false);
          if (createScenario) {
            createScenario('static', staticScenarioTitle.value);
            setStaticScenarioTitle({
              ...staticScenarioTitle,
              value: '',
            });
            toast.success('Plan locked');
          }
        }}
        scenarioTitle={staticScenarioTitle}
        setScenarioTitle={setStaticScenarioTitle}
        scenarioType="static"
      />
    </>
  );
};

export default ScenarioMenuDropdown;
