import React, { useEffect, useMemo, useRef, useState } from 'react';
import { headcountLoaderSchema } from '~/pages/Headcount/entity/schemas';
import formatHeadcountCsv from '../utils/formatHeadcountCsv';
import { IPositionDetailsWithOrderedDates } from '../entity/types';
import usePositionFormState from '../components/usePositionFormState';
import { IDatePickerState } from '~/components/DatePicker/useDatePicker';
import emptyHeadcountStateIllustration from '~/assets/emptyHeadcountStateIllustration.svg';
import Typography from '~/components/Typography';
import { SelectState } from '~/components/Select/Select.types';
import request from '~/utils/request';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import useHeadcountData from '../useHeadcountData';
import { StatusCodes } from 'http-status-codes';
import useEditPositionFormState from '../components/HeadcountTimeline/EditPosition/useEditPositionFormState';
import { isBefore } from 'date-fns';
import date from '~/utils/dates/date';
import isEqual from 'lodash/isEqual';
import logger from '~/utils/logger';
import { handleCreateScenario } from '~/utils/handleCreateScenario';
import { IAPIResponse } from '~/utils/types';
import { useInput } from '~/components/Input';

export interface IHeadcountContext {
  createPositionModalIsOpen: boolean;

  setCreatePositionModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  csvToExport: (string | number | boolean | null | undefined)[][];
  reload: () => void;
  positionFormState: {
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    organizationUuid: string;
    hireDate: IDatePickerState;
    setHireDate: React.Dispatch<React.SetStateAction<IDatePickerState>>;
    employeeName: Types.InputState;
    setEmployeeName: React.Dispatch<React.SetStateAction<Types.InputState>>;
    title: Types.InputState;
    setTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
    payRate: Types.InputState;
    selectDepartment: SelectState;
    setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
    setPayRate: React.Dispatch<React.SetStateAction<Types.InputState>>;
    resetFormState: () => void;
    numberToCreate: Types.InputState;
    setNumberToCreate: React.Dispatch<React.SetStateAction<Types.InputState>>;
    isChecked: boolean;
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  };
  emptyTableState: React.ReactNode;
  positions?: IPositionDetailsWithOrderedDates[];
  setPositions: React.Dispatch<React.SetStateAction<IPositionDetailsWithOrderedDates[]>>;
  deletePosition: ({
    positionUuid,
    createScenario,
  }: {
    positionUuid: string;
    createScenario?: boolean;
  }) => Promise<void>;
  addTermDateState: {
    termDate: IDatePickerState | null;
    positionUuid: string | null;
    containerRef: React.RefObject<HTMLDivElement>;
  };
  setAddTermDateState: React.Dispatch<
    React.SetStateAction<{
      termDate: IDatePickerState | null;
      positionUuid: string | null;
      containerRef: React.RefObject<HTMLDivElement>;
    }>
  >;
  openTermDateRowIndex: number | null;
  setOpenTermDateRowIndex: React.Dispatch<React.SetStateAction<number | null>>;
  pageLoading: boolean;
  deleteIsLoading: boolean;
  showTermedPositions: boolean;
  setShowTermedPositions: React.Dispatch<React.SetStateAction<boolean>>;
  renderedPositions: IPositionDetailsWithOrderedDates[];
  setRenderedPositions: React.Dispatch<React.SetStateAction<IPositionDetailsWithOrderedDates[]>>;
  hasReloaded: boolean;
  setHasReloaded: React.Dispatch<React.SetStateAction<boolean>>;
  editPositionUuid: string | null;
  setEditPositionUuid: React.Dispatch<React.SetStateAction<string | null>>;
  editPositionFormState: {
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    organizationUuid: string;
    employeeName: Types.InputState;
    setEmployeeName: React.Dispatch<React.SetStateAction<Types.InputState>>;
    title: Types.InputState;
    setTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
    selectDepartment: SelectState;
    setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
    resetFormState: () => void;
    hireDate: IDatePickerState;
    setHireDate: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  };
  positionActiveStateDict: Record<string, boolean>;
  setPositionActiveStateDict: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  search: Types.InputState;
  setSearch: React.Dispatch<React.SetStateAction<Types.InputState>>;
  fullyBurdenedCostDict: Record<string, number | undefined | null>;
  setFullyBurdenedCostDict: React.Dispatch<React.SetStateAction<Record<string, number | undefined | null>>>;
}

export const HeadcountContext = React.createContext({} as IHeadcountContext);

const emptyTableState = (
  <div className="flex flex-col items-center gap-4 py-16 bg-white">
    <img src={emptyHeadcountStateIllustration} alt="Empty Headcount Illustration" className="w-36 h-auto" />
    <Typography color="empty">No Positions</Typography>
  </div>
);

export const HeadcountProvider = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const headcountData = useHeadcountData();
  const revalidate = headcountData.revalidate;
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const [positions, setPositions] = useState<IPositionDetailsWithOrderedDates[]>([]);
  const [renderedPositions, setRenderedPositions] = useState<IPositionDetailsWithOrderedDates[]>([]);
  const [showTermedPositions, setShowTermedPositions] = useState<boolean>(false);
  const [hasReloaded, setHasReloaded] = useState<boolean>(true);
  const [editPositionUuid, setEditPositionUuid] = useState<string | null>(null);
  const [positionActiveStateDict, setPositionActiveStateDict] = useState<Record<string, boolean>>({});
  const [search, setSearch] = useInput({
    validation: /.*/,
  });
  const [fullyBurdenedCostDict, setFullyBurdenedCostDict] = useState<Record<string, number | undefined | null>>({});
  const fetchedPositions = useMemo(() => {
    return headcountData.data ? headcountLoaderSchema.parse(headcountData.data).positions ?? [] : [];
  }, [headcountData.data]);

  const editPositionFormState = useEditPositionFormState(editPositionUuid);

  useEffect(() => {
    let filteredPositions;
    if (hasReloaded) {
      filteredPositions = fetchedPositions;
    } else {
      if (fetchedPositions.length > 0) {
        filteredPositions = fetchedPositions;
      } else {
        filteredPositions = fetchedPositions;
      }
    }
    if (!showTermedPositions && filteredPositions.length > 0) {
      filteredPositions = filteredPositions.filter(
        (position) => (position.termDate && !isBefore(position.termDate, date())) || !position.termDate,
      );
    }
    if (search.value) {
      filteredPositions = filteredPositions.filter((position) => {
        const employeeNameMatch = position.employeeName?.toLowerCase().includes(search.value.toLowerCase());
        const titleMatch = position.title.toLowerCase().includes(search.value.toLowerCase());
        return employeeNameMatch || titleMatch;
      });
    }
    const positionsToRender = filteredPositions.map((position) => ({
      ...position,
      orderedDate: position.hireDate,
    }));

    const sortedPositionsToRender = [...positionsToRender].sort((a, b) => a.positionUuid.localeCompare(b.positionUuid));
    const sortedRenderedPositions = [...renderedPositions].sort((a, b) => a.positionUuid.localeCompare(b.positionUuid));

    if (!isEqual(sortedPositionsToRender, sortedRenderedPositions)) {
      setRenderedPositions(positionsToRender);
    }

    let positionsToSet;
    if (hasReloaded) {
      positionsToSet = fetchedPositions;
    } else {
      if (positions.length > 0) {
        positionsToSet = positions;
      } else {
        positionsToSet = fetchedPositions;
      }
    }
    setPositions(
      positionsToSet.map((position) => ({
        ...position,
        orderedDate: position.hireDate,
      })),
    );
    if (hasReloaded) {
      setHasReloaded(false);
    }
  }, [fetchedPositions, showTermedPositions, search]);

  const [createPositionModalIsOpen, setCreatePositionModalIsOpen] = useState(false);
  const timelineRef = useRef<HTMLDivElement>(null);
  const [addTermDateState, setAddTermDateState] = useState<{
    termDate: IDatePickerState | null;
    positionUuid: string | null;
    containerRef: React.RefObject<HTMLDivElement>;
  }>({
    termDate: null,
    positionUuid: null,
    containerRef: timelineRef,
  });
  const [openTermDateRowIndex, setOpenTermDateRowIndex] = useState<number | null>(null);
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const activeStateDict = fetchedPositions.reduce(
      (acc, position) => {
        if (!activeScenarioUuid && !position.isActive) {
          logger.error(new Error(`Position ${position.positionUuid} is marked inactive in base model`));
        }
        acc[position.positionUuid] = activeScenarioUuid ? position.isActive : true;
        return acc;
      },
      {} as Record<string, boolean>,
    );
    setPositionActiveStateDict(activeStateDict);
  }, [fetchedPositions]);

  useEffect(() => {
    const fullyBurdenedCostDict = fetchedPositions.reduce(
      (acc, position) => {
        acc[position.positionUuid] = position.fullyBurdenedCost ?? 0;
        return acc;
      },
      {} as Record<string, number | undefined | null>,
    );
    setFullyBurdenedCostDict(fullyBurdenedCostDict);
  }, [fetchedPositions]);

  const csvToExport = formatHeadcountCsv({
    positions,
  });
  const positionFormState = usePositionFormState();

  const deletePosition = async ({
    positionUuid,
    createScenario,
  }: {
    positionUuid: string;
    createScenario?: boolean;
  }): Promise<void> => {
    try {
      setDeleteIsLoading(true);
      const deletePositionResponse = (await request({
        method: 'DELETE',
        url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
        params: {
          scenarioUuid: activeScenarioUuid ?? undefined,
          createScenario: !activeScenarioUuid && createScenario ? true : undefined,
        },
      })) as IAPIResponse;

      if (deletePositionResponse.status === StatusCodes.NO_CONTENT) {
        handleCreateScenario({ response: deletePositionResponse });
        toast.success('Position deleted successfully');
        setPositions((prev) =>
          prev.filter((position) => {
            if ('uuid' in position) {
              return position.uuid !== positionUuid;
            } else {
              return position.positionUuid !== positionUuid;
            }
          }),
        );
        setRenderedPositions((prev) =>
          prev.filter((position) => {
            if ('uuid' in position) {
              return position.uuid !== positionUuid;
            } else {
              return position.positionUuid !== positionUuid;
            }
          }),
        );
      } else {
        toast.error('Failed to delete position');
      }
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
      toast.error('An error occurred while deleting the position');
    } finally {
      setDeleteIsLoading(false);
    }
  };

  return (
    <HeadcountContext.Provider
      value={{
        createPositionModalIsOpen,
        setCreatePositionModalIsOpen,
        csvToExport,
        reload: revalidate,
        positionFormState,
        emptyTableState,
        positions,
        setPositions,
        deletePosition,
        addTermDateState,
        setAddTermDateState,
        openTermDateRowIndex,
        setOpenTermDateRowIndex,
        pageLoading: headcountData.loading,
        deleteIsLoading,
        showTermedPositions,
        setShowTermedPositions,
        renderedPositions,
        setRenderedPositions,
        hasReloaded,
        setHasReloaded,
        editPositionUuid,
        setEditPositionUuid,
        editPositionFormState,
        positionActiveStateDict,
        setPositionActiveStateDict,
        search,
        setSearch,
        fullyBurdenedCostDict,
        setFullyBurdenedCostDict,
      }}
    >
      {children}
    </HeadcountContext.Provider>
  );
};

export default HeadcountProvider;
