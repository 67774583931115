import React, { useEffect, useRef, useState } from 'react';
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  DragOverEvent,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Header from '~/components/Header';
import Legend from './Components/Legend';
import * as api from '~/services/parallel';
import { State } from '~/store';
import { useSelector } from 'react-redux';
import { IFormula, IFormulaGroupSorting } from '~/services/parallel/formulas.types';
import GroupRow from './Components/GroupRow';
import HeaderRow from './Components/HeaderRow';
import cloneDeep from 'lodash/cloneDeep';
import objectHash from 'object-hash';

import { IIntegrationMapping } from '~/services/parallel/integrations.types';
import AttributeRow from './Components/AttributeRow';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import Modal from '~/components/Modal';
import ManageGroups from './Components/ManageGroups';
import { isEqual } from 'lodash';
import toast from 'react-hot-toast';
import logger from '~/utils/logger';
import Button from '~/components/Button';
import FullPageLoading from '~/components/FullPageLoading';

interface IGroup {
  isOpen: boolean;
  name: string;
  formulas: IFormula[];
  isDraggingOver: boolean;
}

interface IFinancialModelData {
  isPageLoading: boolean;
  groups: IGroup[];
  createdGroup: string | null;
  createdAttribute: string | null;
  attributeEdit: string | null;
  integrationMappings: Record<string, string> | null;
}

const FinancialModel = (): React.ReactNode => {
  const { organization, scenario } = useSelector((state: State) => state);
  const [leadingColumnWidths] = useState<[number, number, number]>([25, 250, 450]); // [drag handle width, attribute name width, attribute formula width]
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const [manageGroupsModalOpen, setManageGroupsModalOpen] = useState<boolean>(false);
  const [financialModelData, setFinancialModelData] = useState<IFinancialModelData>({
    isPageLoading: true,
    groups: [],
    createdGroup: null,
    createdAttribute: null,
    attributeEdit: null,
    integrationMappings: {},
  });
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 5,
      },
    }),
  );
  const sortOrderRef = useRef<{ name: string; sortOrder: string[] }[] | null>(null);

  useEffect(() => {
    setFinancialModelData((prevState) => ({
      ...prevState,
      isPageLoading: true,
    }));
    const fetchData = async (): Promise<void> => {
      const formulasList = await api.formulas.list({
        organizationUuid: organization.uuid,
        startDate: new Date('2024-06-01T00:00:00.000Z'),
        endDate: new Date('2025-12-01T00:00:00.000Z'),
      });

      const formulasDictionary = formulasList.reduce((output: Record<string, IFormula>, formula) => {
        output[formula.formulaUuid] = formula;
        return output;
      }, {});

      const integrationsList = await api.integrations.list({
        organizationUuid: organization.uuid,
      });

      let integrationMappings: IIntegrationMapping[] = [];
      if (integrationsList.length) {
        integrationMappings = await api.integrations.listMappings({
          organizationUuid: organization.uuid,
          integrationUuid: integrationsList[0].uuid,
        });
      }

      const integrationMappingsDictionary = integrationMappings.reduce(
        (output: Record<string, string>, integrationMapping) => {
          if (integrationMapping.uuid in output) return output;
          const integrationSlug = integrationsList.find(
            (integration) => integration.uuid === integrationMapping.integrationUuid,
          );
          if (integrationSlug) {
            output[integrationMapping.uuid] = integrationSlug.source?.slug ?? '';
          }
          return output;
        },
        {},
      );

      const sortOrder = await api.formulas.getSortOrder({
        organizationUuid: organization.uuid,
      });

      const groups = sortOrder.groups.reduce((output: IGroup[], group) => {
        output.push({
          isOpen: true,
          name: group.name,
          formulas: group.sortOrder
            .map((formulaUuid) => formulasDictionary[formulaUuid])
            .filter((formula) => formula !== undefined), // Filter out undefined values. Stop gap to prevent bad sort orders
          isDraggingOver: false,
        });
        return output;
      }, []);

      setFinancialModelData((prevState) => ({
        ...prevState,
        isPageLoading: false,
        groups,
        integrationMappings: integrationMappingsDictionary,
      }));
    };
    if (organization.uuid) {
      fetchData();
    }
  }, []);

  const toggleGroupCollapse = (name: string): void => {
    setFinancialModelData((prevState) => ({
      ...prevState,
      groups: prevState.groups.map((group) => (group.name === name ? { ...group, isOpen: !group.isOpen } : group)),
    }));
  };

  const openCreateNewAttributeModal = (): void => {
    setFinancialModelData((prevState) => ({
      ...prevState,
      attributeEdit: 'new',
    }));
  };

  const handleDragStart = (event: DragStartEvent): void => {
    setDraggingId(event.active.id.toString());
  };

  const handleDragEnd = (event: DragEndEvent): void => {
    setDraggingId(null);
    const { active, over } = event;
    const activeId = active.id;
    const overId = over?.id;

    // Dropped into same location
    if (!overId || activeId === overId) {
      setFinancialModelData((prevState) => ({
        ...prevState,
        groups: prevState.groups.map((group) => ({
          ...group,
          isDraggingOver: false,
        })),
      }));
      return;
    }

    setFinancialModelData((prevState) => {
      const clonedGroups = cloneDeep(prevState.groups);
      // Find the group and formula of the active item
      const sourceGroup = clonedGroups.find((group) =>
        group.formulas.some((formula) => formula.formulaUuid === activeId),
      );

      // Find the target group, even if it is closed
      const targetGroup = clonedGroups.find(
        (group) => group.formulas.some((formula) => formula.formulaUuid === overId) || group.name === overId,
      );

      if (sourceGroup && targetGroup) {
        const sourceIndex = sourceGroup.formulas.findIndex((f) => f.formulaUuid === activeId);
        const targetIndex = targetGroup.formulas.findIndex((f) => f.formulaUuid === overId);

        // If moving within the same group, reorder
        if (sourceGroup.name === targetGroup.name) {
          const [movedFormula] = sourceGroup.formulas.splice(sourceIndex, 1);

          if (sourceIndex > targetIndex) {
            // Moving down the list
            targetGroup.formulas.splice(targetIndex + 1, 0, movedFormula);
          } else {
            // Moving up the list
            targetGroup.formulas.splice(targetIndex, 0, movedFormula);
          }
        } else {
          // Remove the item from its original position
          const [movedFormula] = sourceGroup.formulas.splice(
            sourceGroup.formulas.findIndex((f) => f.formulaUuid === activeId),
            1,
          );
          if (!targetGroup.isOpen) {
            // If moving to a closed group, add it to the beginning of the target group
            targetGroup.formulas.unshift(movedFormula);
          } else {
            // Add to after the target index
            targetGroup.formulas.splice(targetIndex + 1, 0, movedFormula);
          }
        }
      }

      return {
        ...prevState,
        groups: clonedGroups.map((group) => ({
          ...group,
          isDraggingOver: false,
        })),
      };
    });
  };

  const handleDragOver = (event: DragOverEvent): void => {
    const { active, over } = event;
    const activeId = active.id;
    const overId = over?.id;

    if (!overId || activeId === overId) return;

    setFinancialModelData((prevState) => ({
      ...prevState,
      groups: prevState.groups.map((group) => ({
        ...group,
        isDraggingOver: group.name === overId,
      })),
    }));
  };

  const isFullyCollapsed = financialModelData.groups.every((group) => !group.isOpen);

  const toggleCollapse = (): void => {
    setFinancialModelData((prevState) => ({
      ...prevState,
      groups: prevState.groups.map((group) => ({
        ...group,
        isOpen: isFullyCollapsed,
      })),
    }));
  };

  useEffect(() => {
    const updateSortOrder = async (groups: IFormulaGroupSorting[]): Promise<void> => {
      try {
        await api.formulas.updateSortOrder({
          organizationUuid: organization.uuid,
          groups,
          scenarioUuid: scenario.activeScenarioData?.uuid ?? '',
        });
      } catch (error) {
        toast.error('Unable to update a sort order');
        if (error instanceof Error) {
          logger.error(error);
        }
      }
    };

    if (financialModelData.groups.length) {
      const previousSortOrder = sortOrderRef.current;

      sortOrderRef.current = financialModelData.groups.map((group) => ({
        name: group.name,
        sortOrder: group.formulas.map((f) => f.formulaUuid),
      }));

      if (previousSortOrder && !isEqual(previousSortOrder, sortOrderRef.current)) {
        updateSortOrder(sortOrderRef.current);
      }
    }
  }, [financialModelData.groups]);

  const evaluateGroupChanges = (newlySortedGroups: { id: string; name: string; sortOrder: string[] }[]): void => {
    setFinancialModelData((prevState) => {
      const updatedGroups: IGroup[] = [];
      newlySortedGroups.forEach((newlySortedGroup) => {
        const existingGroup = prevState.groups.find((g) => {
          const existingGroupHash = objectHash({
            name: g.name,
            sortOrder: g.formulas.map((f) => f.formulaUuid),
          });
          return newlySortedGroup.id === existingGroupHash;
        });

        if (existingGroup) {
          updatedGroups.push({
            ...existingGroup,
            name: newlySortedGroup.name,
          });
        } else {
          updatedGroups.push({
            name: newlySortedGroup.name,
            formulas: [],
            isOpen: true,
            isDraggingOver: false,
          });
        }
      });

      return {
        ...prevState,
        groups: updatedGroups,
      };
    });
  };

  const allFormulas = financialModelData.groups.reduce((output: IFormula[], group) => {
    output.push(...group.formulas);
    return output;
  }, []);

  return (
    <div className="w-full bg-neutral-15">
      <Header
        title="Financial Model"
        startChildren={<Legend />}
        endChildren={
          <div className="flex items-center">
            <EllipsisDropdown
              options={[
                {
                  label: 'Manage Groups',
                  onClick: () => setManageGroupsModalOpen(true),
                },
                {
                  label: 'Add Pre-built Components',
                  onClick: () => {},
                },
                {
                  label: 'Export Values',
                  onClick: () => {},
                },
              ]}
            />
          </div>
        }
      />
      {financialModelData.isPageLoading && (
        <FullPageLoading
          isVisible={financialModelData.isPageLoading}
          color="green"
          text=""
          opacity="1"
          size="size-[75px]"
        />
      )}
      <div className="w-full">
        <HeaderRow
          toggleCollapse={toggleCollapse}
          isFullyCollapsed={isFullyCollapsed}
          leadingColumnWidths={leadingColumnWidths}
        />
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
        >
          <SortableContext
            items={financialModelData.groups.reduce((output: string[], group) => {
              output.push(group.name); // Include group name
              const uuids: string[] = group.formulas.map((formula) => formula.formulaUuid);
              output.push(...uuids);
              return output;
            }, [])}
            strategy={verticalListSortingStrategy}
          >
            {financialModelData.groups.map((group) => (
              <React.Fragment key={group.name}>
                <GroupRow
                  name={group.name}
                  isOpen={group.isOpen}
                  toggleGroupCollapse={toggleGroupCollapse}
                  numberOfChildren={group.formulas.length}
                  openCreateNewAttributeModal={openCreateNewAttributeModal}
                  isDraggingOver={group.isDraggingOver}
                />
                {group.isOpen &&
                  group.formulas.map((formula: IFormula) => {
                    const integrationSlug = formula.dataSourceUuid
                      ? financialModelData.integrationMappings?.[formula.dataSourceUuid]
                      : undefined;

                    const isReferencedElsewhere = allFormulas.some((f) =>
                      Object.values(f.recipe.variables).some(
                        (variable) => variable.formulaUuid === formula.formulaUuid,
                      ),
                    );
                    return (
                      <AttributeRow
                        key={formula.formulaUuid}
                        formula={formula}
                        integrationSlug={integrationSlug}
                        isIsolated={!isReferencedElsewhere}
                      />
                    );
                  })}
              </React.Fragment>
            ))}
            <DragOverlay dropAnimation={null}>
              {draggingId ? (
                <div className="h-[44px] w-[300px] bg-gradient-to-r from-white to-transparent flex items-center cursor-grab">
                  {financialModelData.groups.reduce((result: string | null, group) => {
                    if (result) return result;
                    const formula = group.formulas.find((formula) => formula.formulaUuid === draggingId);
                    return formula?.recipe.name ?? null;
                  }, null)}
                </div>
              ) : null}
            </DragOverlay>
          </SortableContext>
        </DndContext>
      </div>
      <div className="mt-[6px] px-4 py-[9px] pt-4 bg-white border-t border-neutral-50 w-full flex items-center justify-between">
        <Button fill="clear" className="!w-auto !p-0" onClick={() => setManageGroupsModalOpen(true)}>
          Manage Groups
        </Button>
        <Legend />
      </div>
      <Modal
        isOpen={manageGroupsModalOpen}
        onClose={() => setManageGroupsModalOpen(false)}
        title="Edit Groups"
        size="xxs"
      >
        <ManageGroups
          groups={financialModelData.groups}
          onUpdate={(updatedSortOrder) => {
            if (updatedSortOrder) {
              evaluateGroupChanges(updatedSortOrder);
            }
            setManageGroupsModalOpen(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default FinancialModel;
