import React from 'react';
import Typography from '~/components/Typography';
import quickbooksIcon from '~/assets/logos/quickbooks.svg';
import xeroIcon from '~/assets/logos/xero.svg';
import { IFormula } from '~/services/parallel/formulas.types';
import DraggableItem from './DraggableItem';
import { ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import HoverPopover from '~/components/HoverPopover';

const integrationIconMapping = {
  'quickbooks-online': quickbooksIcon,
  xero: xeroIcon,
};

const AttributeRow = ({
  formula,
  integrationSlug,
  isIsolated,
}: {
  formula: IFormula;
  integrationSlug?: string;
  isIsolated: boolean;
}): React.ReactNode => {
  return (
    <DraggableItem key={formula.formulaUuid} id={formula.formulaUuid} className="border-b border-neutral-50 bg-white">
      <div className="flex justify-between w-full pr-2 pl-2 py-3 group">
        <div className="flex">
          <Typography>{formula.recipe.name}</Typography>
        </div>
        <div className="flex items-center gap-1">
          {isIsolated && (
            <HoverPopover
              buttonContent={<ViewfinderCircleIcon className="size-5" />}
              panelContent={
                <div className="flex flex-col text-nowrap justify-start">
                  <Typography size="xs" weight="bold">
                    Isolated Attribute
                  </Typography>
                  <Typography size="xs">
                    This attribute is not used to
                    <br />
                    calculate any other attributes
                  </Typography>
                </div>
              }
              panelClassName="!bg-white !shadow !rounded-md !py-2 !px-4"
              anchor="top"
            />
          )}
          {integrationSlug && (
            <div key={`${formula.formulaUuid}-${integrationSlug}`}>
              <img
                className="size-5"
                src={integrationIconMapping[integrationSlug as keyof typeof integrationIconMapping]}
                alt={`Integration icon for ${formula.dataSourceUuid}`}
              />
            </div>
          )}
        </div>
      </div>
    </DraggableItem>
  );
};

export default AttributeRow;
