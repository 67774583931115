import { z } from 'zod';

export type IEntitySyncStatus = 'syncing' | 'synced';
export const IEntitySyncStatus = {
  SYNCING: 'syncing',
  SYNCED: 'synced',
} as const;

export type IIntegrationSources = 'quickbooks-online' | 'xero';
export const IIntegrationSources = {
  Quickbooks: 'quickbooks-online',
  Xero: 'xero',
} as const;

export type IRemoteOptions = 'merge';
export const IRemoteOptions = {
  Merge: 'merge',
} as const;

export type IIntegrationMetadata = z.infer<typeof ZIntegrationMetadata>;

export type IIntegrationCategory = z.infer<typeof ZIntegrationCategory>;

export interface IIntegrationCreate {
  uuid?: string;
  organizationUuid: string;
  lastSyncAttempt?: Date;
  lastSyncSuccess?: Date;
  category: IIntegrationCategory;
  sourceUuid: string;
  entities: string[];
  integrationMetadata: IIntegrationMetadata;
  isCredentialsValid: boolean;
}

export const ZIntegrationMetadata = z.object({
  publicToken: z.string(),
  entitySyncStatus: z.array(
    z.object({
      name: z.string(),
      slug: z.string(),
      status: z.nativeEnum(IEntitySyncStatus),
      lastSyncStartAt: z.string(),
    }),
  ),
});

export const ZIntegrationCategory = z.enum(['accounting', 'finance']);

export const ZIntegrationEntity = z.enum(['BalanceSheet', 'CashFlowStatement']);
export type IIntegrationEntity = z.infer<typeof ZIntegrationEntity>;

export type IIntegration = z.infer<typeof ZIntegration>;

export const ZIntegrationSource = z.object({
  uuid: z.string(),
  createdAt: z.date(),
  updatedAt: z.date().nullable(),
  name: z.string(),
  slug: z.nativeEnum(IIntegrationSources),
  category: z.lazy(() => ZIntegrationCategory),
  entities: z.array(ZIntegrationEntity),
});

export const ZIntegration = z.object({
  uuid: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  organizationUuid: z.string(),
  lastSyncAttempt: z.date().nullable(),
  lastSyncSuccess: z.date().nullable(),
  integrationMetadata: ZIntegrationMetadata,
  sourceUuid: z.string(),
  category: ZIntegrationCategory,
  entities: z.array(z.string()),
  isCredentialsValid: z.boolean(),
  source: ZIntegrationSource,
});

export const ZIntegrationMapping = z.object({
  uuid: z.string(),
  createdAt: z.date({ coerce: true }),
  updatedAt: z.date({ coerce: true }).nullable(),
  integrationUuid: z.string(),
  name: z.string(),
  remoteId: z.string(),
  entity: z.string(),
});

export type IIntegrationMapping = z.infer<typeof ZIntegrationMapping>;
