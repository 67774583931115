import React, { ReactElement, useEffect, useState } from 'react';
import SideMenu from '~/components/SideMenu';
import store, { State } from '~/store';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import * as utils from '~/pages/ProtectedRoute/utils/';
import { Channel } from 'pusher-js';
import ConversationBox from '~/components/ConversationBox';
import ScenarioMode from '~/components/ScenarioMode';
import toast from 'react-hot-toast';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import waitForStoreRehydration from '~/utils/waitForStoreRehydration';
import FullPageLoading from '~/components/FullPageLoading';

const ProtectedRoute = (): ReactElement => {
  const user = useSelector((state: State) => state.user);
  const isLoggedIn = useSelector((state: State) => state.auth.isLoggedIn);
  const pusher = useSelector((state: State) => state._sockets.pusher);
  const location = useLocation();
  const navigate = useNavigate();
  const [showRefresh, setShowRefresh] = useState<boolean>(false);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await waitForStoreRehydration(store);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  utils.useInitializePusher();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isLoading) {
        throw new Error('Loading failed');
      }
    }, 15000);

    return () => clearTimeout(timeoutId);
  }, []);

  /**
   * Listen for NEW_DEPLOYMENT events and show the refresh button if one comes through.
   */
  useEffect(() => {
    let channel: Channel | null = null;
    if (pusher) {
      channel = pusher.subscribe('ALL');
      channel.bind('NEW_DEPLOYMENT', () => {
        setShowRefresh(true);
      });
    }
    return () => {
      if (channel) channel.disconnect();
    };
  }, [pusher]);

  useEffect(() => {
    let userNotificationChannel: Channel | null = null;
    if (organizationUuid) {
      if (pusher) {
        userNotificationChannel = pusher.subscribe(organizationUuid);

        userNotificationChannel.bind(
          'notification-created',
          (
            data: {
              userUuid: string;
              organizationUuid: string;
              type: string;
              context: { title: string };
            }[],
          ) => {
            if (location.pathname !== '/ratios') {
              data.map((item) => {
                if (item.userUuid === user.uuid) {
                  toast.custom(
                    (t) => (
                      <div className="flex flex-row bg-white text-nowrap gap-2 shadow-md px-4 py-1 justify-center items-center rounded">
                        <Typography weight="semibold">{item.context.title.replace('@', ':')}</Typography>
                        <Typography>ratio impacted</Typography>
                        <Button
                          fill="clear"
                          onClick={() => {
                            navigate('/ratios');
                            toast.dismiss();
                          }}
                          className="hover:underline !w-fit !px-0"
                        >
                          View
                        </Button>
                        <Button
                          fill="clear"
                          onClick={() => toast.dismiss(t.id)}
                          className="!p-0 !w-fit  !text-neutral-100 !hover:text-neutral-400"
                        >
                          <XMarkIcon className="h-5 w-5 stroke-2" />
                        </Button>
                      </div>
                    ),
                    { duration: 6000 },
                  );
                }
              });
            }
          },
        );
      }
    }
    return () => {
      if (userNotificationChannel) {
        userNotificationChannel.unbind('notification-created');
      }
    };
  }, [pusher, organizationUuid, user.uuid, location.pathname, navigate]);

  /**
   * Initialize datadog with user information upon login.
   */
  useEffect(() => {
    if (user.uuid && isLoggedIn) {
      utils.datadog({ user, isLoggedIn: isLoggedIn });
    }
  }, [user, isLoggedIn]);

  if (isLoggedIn) {
    return (
      <>
        <ScenarioMode />
        <SideMenu showRefresh={showRefresh}>
          {isLoading ? (
            <FullPageLoading isVisible={true} color="green" text="" opacity="1" size="size-[75px]" />
          ) : (
            <Outlet />
          )}
          {<ConversationBox />}
        </SideMenu>
      </>
    );
  }

  return (
    <Navigate
      to={`/auth/login?redirectUrl=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`}
      replace
    />
  );
};

export default ProtectedRoute;
