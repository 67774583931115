import React from 'react';
import Typography from '~/components/Typography';
import { IFormattingEnum } from '~/pages/FinancialModelDeprecated/entity/schemas';
import formatCurrency from '~/utils/formatCurrency';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import { formatNumber } from '~/utils/formatNumber';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { deleteGoal } from './deleteGoal';
import { StatusCodes } from 'http-status-codes';
import toast from 'react-hot-toast';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import { IGraphData } from '../../Dashboard/entity/types';

const formatTargetValue = (targetValue: number, formatting: IFormattingEnum): string => {
  switch (formatting) {
    case IFormattingEnum.Number:
      return formatNumber(Math.round(targetValue));
    case IFormattingEnum.Percent:
      return `${targetValue.toFixed(2)}%`;
    case IFormattingEnum.Currency:
      return formatCurrency(targetValue, false);
    default:
      return targetValue.toFixed(2);
  }
};

const GoalTile = ({
  goalUuid,
  formulaUuid,
  targetValue,
  targetDate,
  onDelete,
  setCreateGoalModal,
}: {
  goalUuid: string;
  formulaUuid: string;
  formatting?: IFormattingEnum;
  targetValue: number;
  targetDate: Date | null;
  onDelete: (goalUuid: string) => void;
  setCreateGoalModal: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      goalUuid: string | null;
      formulaUuid: string | null;
      targetValue: string | null;
      targetDate: string | null;
    }>
  >;
}): React.ReactElement => {
  const revalidator = useRevalidator();
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { reports } = useLoaderData() as { reports: IGraphData };
  const formulaTitle = reports.consolidated?.[formulaUuid]?.title ?? 'No title found';
  const formatting = reports.consolidated?.[formulaUuid]?.formatting ?? IFormattingEnum.Number;

  const handleDeleteGoal = async (): Promise<void> => {
    try {
      const response = await deleteGoal({
        goalUuid,
        organizationUuid,
      });

      if (response.status === StatusCodes.NO_CONTENT) {
        onDelete(goalUuid);
        await revalidator.revalidate();
      } else {
        toast.error('Failed to delete goal');
      }
    } catch (error) {
      toast.error('Failed to delete goal');
    }
  };

  return (
    <div className="flex w-full items-center justify-between border border-neutral-50 rounded-lg px-4 py-2 group">
      <span className="flex gap-2">
        <Typography>{formulaTitle}</Typography>
        <Typography color="empty">of</Typography>
        <Typography>{formatTargetValue(targetValue, formatting)}</Typography>
        {targetDate && (
          <React.Fragment>
            <Typography color="empty">by</Typography>
            <Typography>{format(targetDate, 'MMM yyyy')}</Typography>
          </React.Fragment>
        )}
      </span>
      <div className="flex items-center gap-2">
        <PencilSquareIcon
          className="size-5 text-neutral-100 cursor-pointer hover:text-green-400"
          onClick={() =>
            setCreateGoalModal({
              open: true,
              goalUuid: goalUuid,
              formulaUuid: formulaUuid,
              targetValue:
                formatting === IFormattingEnum.Percent
                  ? targetValue.toString()
                  : (targetValue / CENTS_PER_DOLLAR).toString(),
              targetDate: targetDate?.toDateString() ?? null,
            })
          }
        />
        <TrashIcon className="size-5 text-neutral-100 cursor-pointer hover:text-red-500" onClick={handleDeleteGoal} />
      </div>
    </div>
  );
};

export default GoalTile;
