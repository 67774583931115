import React, { useState } from 'react';
import Input from '~/components/Input/InputWrapper';
import Button from '~/components/Button';
import SegmentedControl from '~/components/SegmentedControl';
import { ExpenseType, IExpenseFormState } from '~/pages/Expenses/components/Expenses/createExpense/useExpenseFormState';
import PeriodPicker from '~/components/PeriodPicker';
import SelectMultipleDepartments from '~/components/SelectMultipleDepartments';
import Divider from '~/components/Divider';
import RadioInputTile from '~/components/RadioInputTile';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';
import Select from '~/components/Select';

const EXPENSE_TYPE_OPTIONS = [
  {
    label: 'Set Cost',
    value: 'setCost',
  },
  {
    label: 'Headcount Driven',
    value: 'headcountDriven',
  },
];

interface Props {
  onClose: () => void;
  formState: IExpenseFormState;
  createExpense: () => Promise<void>;
}

const CreateExpenseForm = ({
  onClose,
  formState: {
    errorMessage,
    type,
    setType,
    name,
    setName,
    category,
    setCategory,
    frequency,
    setFrequency,
    headcountDriverType,
    setHeadcountDriverType,
    amount,
    setAmount,
    percentage,
    setPercentage,
    departments,
    setDepartments,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  },
  createExpense,
}: Props): React.ReactNode => {
  const getCurrencyLabel = ({ type, frequency }: { type: string; frequency?: string }): string => {
    if (frequency === 'oneTime' && type === 'setCost') return 'One Time Cost';
    if (frequency === 'oneTime') return 'Cost Per Employee';
    if (frequency === 'annually') return 'Annual Cost';
    if (frequency === 'quarterly') return 'Quarterly Cost';
    if (frequency === 'onHire') return 'Cost Per Hire';
    if (type !== 'setCost') return 'Monthly Cost Per Employee';

    return 'Monthly Cost';
  };
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false);

  const handleSave = async (): Promise<void> => {
    setSaveIsLoading(true);
    await createExpense();
    setSaveIsLoading(false);
  };

  return (
    <div className="container px-0">
      <div className="w-full flex flex-col">
        <div className="w-full flex flex-col gap-4 mb-2">
          <SegmentedControl
            name="type-control"
            value={type}
            setValue={(val) => {
              setType(val as ExpenseType);
            }}
            segments={EXPENSE_TYPE_OPTIONS}
          />
          <Input id="expenseName" label="Expense Name" state={name} setState={setName} />
          <RadioInputTile
            id="expenseCategory"
            label="Category"
            state={category}
            setState={(val) =>
              setCategory((prevState) => ({
                ...prevState,
                selected: val,
                touched: true,
                valid: true,
              }))
            }
            required
            disabled={false}
          />
          <Divider />
          <RadioInputTile
            id="expenseFrequency"
            label="Frequency"
            state={frequency}
            setState={(val) => {
              setFrequency((prevState) => ({
                ...prevState,
                selected: val,
                touched: true,
                valid: true,
              }));
              if (val.value === 'oneTime') {
                setEndDate((prevState) => {
                  return {
                    ...prevState,
                    startDate: null,
                    endDate: null,
                    valid: true,
                    touched: false,
                    pristine: true,
                  };
                });
              }
              if (val.value === 'onHire') {
                setHeadcountDriverType({
                  ...headcountDriverType,
                  selected: {
                    label: 'Dollar Amount per Employee',
                    value: 'headcountFixed',
                  },
                  disabled: true,
                });
              } else {
                setHeadcountDriverType({
                  ...headcountDriverType,
                  disabled: false,
                });
              }
            }}
            required
            disabled={false}
          />
          {type === 'headcountDriven' && (
            <Select
              label="Type"
              id={'expenseHeadcountDriver'}
              state={headcountDriverType}
              setState={setHeadcountDriverType}
            />
          )}
          {type === 'headcountDriven' && headcountDriverType.selected?.value === 'headcountPercentCompensation' ? (
            <Input
              id="expenseAmount"
              type="percentage"
              label="Percentage"
              state={percentage}
              setState={setPercentage}
            />
          ) : (
            <CurrencyInput
              id="expenseAmount"
              label={`${getCurrencyLabel({
                type: type,
                frequency: frequency.selected?.value,
              })}`}
              state={amount}
              setState={setAmount}
            />
          )}
          <SelectMultipleDepartments
            label="Department Allocation"
            departmentOptions={departments}
            setDepartmentOptions={setDepartments}
            placeholder="Select departments"
            allowSelectAll
          />
          <div className="flex flex-row gap-5">
            <div className="relative w-44" data-testid="create-expense-period-picker">
              <PeriodPicker
                id="expense-start-date-picker"
                label="Start Date"
                state={startDate}
                setState={setStartDate}
                beBefore={endDate.startDate}
              />
            </div>
            <div className={`relative w-44 ${frequency.selected?.value === 'oneTime' ? 'hidden' : ''}`}>
              <PeriodPicker
                id="expense-end-date-picker"
                label="End Date"
                clearable
                optional
                state={endDate}
                setState={setEndDate}
                beAfter={startDate.endDate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-6 gap-5">
        {errorMessage && <div className="text-red-500 text-sm">{errorMessage}</div>}
        <div className="flex justify-between gap-5">
          <Button className="!w-auto !px-0" id="cancel-create-expense" fill="clear" onClick={onClose}>
            Cancel
          </Button>
          <Button id="save-expense" onClick={handleSave} className="!w-auto" loading={saveIsLoading}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateExpenseForm;
