import React, { useContext } from 'react';
import Modal from '~/components/Modal';
import { ExpensesPageContext } from '../context/ExpensesContext';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import request from '~/utils/request';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { StatusCodes } from 'http-status-codes';

const DeleteExpenseModal = (): React.ReactElement => {
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { deleteExpense, setDeleteExpense, expenses, setExpenses, revalidateFilteredExpensesReport } =
    useContext(ExpensesPageContext);

  const handleDeleteExpense = async (): Promise<void> => {
    const response = await request({
      method: 'DELETE',
      url: `/expenses/${deleteExpense?.expenseUuid}`,
      params: {
        scenarioUuid: activeScenarioUuid,
      },
      headers: { 'Organization-Uuid': organizationUuid },
    });

    if (response.status === StatusCodes.NO_CONTENT) {
      setExpenses(expenses.filter((expense) => expense.expenseUuid !== deleteExpense?.expenseUuid));
      setDeleteExpense(undefined);
      revalidateFilteredExpensesReport();
    }
  };

  return (
    <Modal
      title="Delete Expense & Purge History"
      isOpen={!!deleteExpense}
      onClose={() => setDeleteExpense(undefined)}
      size="xs"
      id="delete-expense-modal"
    >
      <div className="flex flex-col gap-4">
        <div>
          <Typography color="secondary">
            Deleting this expense will remove all related data, including all historical impact.
          </Typography>
        </div>
        <div className="flex w-full justify-between">
          <div className="w-fit">
            <Button fill="clear" className="!w-fit !px-0" onClick={() => setDeleteExpense(undefined)}>
              Cancel
            </Button>
          </div>
          <div className="w-fit">
            <Button fill="destructive" onClick={() => handleDeleteExpense()}>
              Confirm, Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteExpenseModal;
