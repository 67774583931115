import React, { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IPositionDetailsWithOrderedDates } from '../../entity/types';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import DeletePositionModal from '../DeletePositionModal';
import { State } from '~/store';
import ChangePayRateModal from '../CellPayRate/ChangePayRateModal';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import Typography from '~/components/Typography';
import formatCurrency from '~/utils/formatCurrency';
import { fetchSpecificPositions } from '../../utils/fetchSpecificPositions';
import EditTermDate from './EditTermDate/EditTermDate';
import { TimelineContext } from '~/components/Timeline/TimelineContext';
import PositionToggle from '../PositionToggle';
import { isAfter } from 'date-fns';

const HeadcountTimelineNodeContent = ({
  position,
  createScenario,
  reloadDashboard,
}: {
  position: IPositionDetailsWithOrderedDates;
  createScenario?: boolean;
  reloadDashboard?: () => void;
}): React.ReactNode => {
  const hideSensitiveData = useSelector((state: State) => state.settings.hideSensitiveData);
  const { renderedPositions, positions, setRenderedPositions, setPositions, setEditPositionUuid } =
    useContext(HeadcountContext);
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const { deletePosition, positionActiveStateDict } = useContext(HeadcountContext);
  const { selection } = useContext(TimelineContext);
  const [isChangePayRateModalOpen, setIsChangePayRateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isTermDateModalOpen, setIsTermDateModalOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [isActive, setIsActive] = useState(positionActiveStateDict[position.positionUuid]);

  useEffect(() => {
    setIsActive(positionActiveStateDict[position.positionUuid]);
  }, [positionActiveStateDict[position.positionUuid]]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef.current, position]);

  const handleClickDelete = (): void => {
    if (activeScenarioUuid) {
      deletePosition({
        positionUuid: position.positionUuid,
      });
    } else {
      setIsDeleteModalOpen(true);
    }
  };

  const handlePayChange = (): void => {
    setIsChangePayRateModalOpen(true);
  };

  const handleAddTerm = (): void => {
    setIsTermDateModalOpen(true);
  };

  const handleOptimisticUpdate = async (): Promise<void> => {
    const [updatedPosition] = await fetchSpecificPositions({
      positionUuids: [position.positionUuid],
      organizationUuid,
      scenarioUuid: activeScenarioUuid ?? undefined,
    });

    const updateIndex = renderedPositions.findIndex(
      (renderedPosition) => renderedPosition.positionUuid === position.positionUuid,
    );

    const updatedPositionIndex = positions?.findIndex(
      (oldPosition) => oldPosition.positionUuid === position.positionUuid,
    );

    if (updatedPositionIndex !== undefined && updatedPositionIndex !== -1 && positions) {
      const updatedPositions = [...positions];
      updatedPositions[updatedPositionIndex] = {
        ...updatedPosition,
        orderedDate: updatedPosition.hireDate,
      };
      setPositions(updatedPositions);
    }
    if (updateIndex !== -1) {
      const updatedPositions = [...renderedPositions];
      updatedPositions[updateIndex] = {
        ...updatedPosition,
        orderedDate: updatedPosition.hireDate,
      };
      setRenderedPositions(updatedPositions);
    }
  };

  const isSelected = selection.selected.includes(position.positionUuid);

  return (
    <>
      <div ref={containerRef} className="w-full flex group text-nowrap pl-3">
        <div className="flex-grow flex relative" style={{ maxWidth: containerWidth - 36 }}>
          <div className="left-0 flex-nowrap flex sticky gap-2.5">
            {isAfter(position.hireDate, new Date()) && activeScenarioUuid && (
              <div onClick={(e) => e.stopPropagation()} className="position-toggle">
                <PositionToggle positionUuid={position.positionUuid} successCallback={reloadDashboard} />
              </div>
            )}
            {position.title && (
              <Typography
                weight="bold"
                color={isActive || !activeScenarioUuid ? 'primary' : 'disabled'}
                className={`${!isActive && activeScenarioUuid && 'line-through'}`}
              >
                {position.title}
              </Typography>
            )}
            {position.employeeName && (
              <Typography
                color={isActive || !activeScenarioUuid ? 'primary' : 'disabled'}
                className={`${!isActive && activeScenarioUuid && 'line-through'}`}
              >
                {position.employeeName}
              </Typography>
            )}
            {position.currentPayRate.value && !hideSensitiveData && (
              <Typography
                color={isActive || !activeScenarioUuid ? 'primary' : 'disabled'}
                className={`${!isActive && activeScenarioUuid && 'line-through'}`}
              >
                {formatCurrency(position.currentPayRate.value, false)}
              </Typography>
            )}
          </div>
        </div>
        <div className="sticky right-0 flex-none font-medium">
          <EllipsisDropdown
            className={`pr-1 ${isSelected ? '!bg-green-15' : 'bg-white'}`}
            options={[
              {
                label: 'Edit',
                onClick: () => setEditPositionUuid(position.positionUuid),
              },
              {
                label: 'Pay Change',
                onClick: handlePayChange,
              },
              {
                label: 'Term',
                onClick: handleAddTerm,
              },
              {
                label: 'Delete',
                onClick: () => handleClickDelete(),
                className: 'text-red-500',
              },
            ]}
            id={`${position.positionUuid}-ellipsis-dropdown`}
          />
        </div>
      </div>
      <DeletePositionModal
        id={`delete-position-modal-${position.positionUuid}`}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() =>
          deletePosition({
            positionUuid: position.positionUuid,
            createScenario,
          })
        }
        deleteIsLoading={false}
      />
      <ChangePayRateModal
        id={`change-pay-rate-modal-${position.positionUuid}`}
        isOpen={isChangePayRateModalOpen}
        onClose={() => setIsChangePayRateModalOpen(false)}
        positionUuid={position.positionUuid}
        positionEffectiveAt={position.currentPayRate.effectiveAt}
        changeHistory={position.payRates.map((payRate) => ({
          value: Number(payRate.value),
          effectiveAt: payRate.effectiveAt,
        }))}
        createScenario={createScenario}
      />
      {isTermDateModalOpen && (
        <EditTermDate
          id={`term-date-modal-${position.positionUuid}`}
          isOpen={isTermDateModalOpen}
          onClose={() => setIsTermDateModalOpen(false)}
          positionUuid={position.positionUuid}
          currentTerminationDate={position.termDate}
          minTerminationDate={position.hireDate}
          handleOptimisticUpdate={handleOptimisticUpdate}
          organizationUuid={organizationUuid}
          createScenario={createScenario}
        />
      )}
    </>
  );
};

export default HeadcountTimelineNodeContent;
