import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { IConsolidatedGraphData } from '../../entity/types';
import LineGraph from '~/components/LineGraph';
import { ILineProps } from '~/components/LineGraph/entity/types';
import date from '~/utils/dates/date';
import { format, isSameMonth } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import { reassignSelectedIndexesForNewDate } from '~/store/scenarioSlice';
import { z } from 'zod';
import isEqual from 'lodash.isequal';
import { DashboardPageContext } from '../../context/DashboardContext';
import { IFormattingEnum } from '~/pages/FinancialModelDeprecated/entity/schemas';
import formatToLetterAbbreviatedNumber from '~/utils/formatToLetterAbbreviatedNumber';
import Card from '~/components/Card';
import Typography from '~/components/Typography';
import Skeleton from 'react-loading-skeleton';
import { getDecimalBasedOnValue } from '../../utils/getDecimalBasedOnValue';
import GraphTitle from './GraphTitle';

const ZGraphTitleProps = z.object({
  title: z.string(),
  month: z.string(),
  figures: z.array(z.string()),
  yFormatter: z.function().args(z.number()).returns(z.string()),
});

const ZGraphProps = z.object({
  card: z.object({
    title: z.any(),
  }),
  cardClassName: z.string().optional(),
  data: z.array(
    z.object({
      date: z.number(),
      workingModel: z.number(),
      activeScenario: z.number().optional(),
      scenario1: z.number().optional(),
      scenario2: z.number().optional(),
      scenario3: z.number().optional(),
    }),
  ),
  dataKeys: z.array(z.string()),
  lines: z.array(
    z.object({
      dataKey: z.string(),
      stroke: z.string(),
      isDashed: z.boolean().optional(),
    }),
  ),
  externalActiveIndex: z.number().optional(),
  setExternalActiveIndex: z
    .function()
    .args(z.union([z.number(), z.function().args(z.number()).returns(z.number())]))
    .returns(z.void())
    .optional(),
  timeboundGoals: z
    .array(
      z.object({
        targetValue: z.number(),
        targetDate: z.date(),
      }),
    )
    .optional(),
  nonTimeboundGoals: z
    .array(
      z.object({
        targetValue: z.number(),
      }),
    )
    .optional(),
});

const DashboardGraphs = ({
  consolidatedReports,
}: {
  consolidatedReports?: IConsolidatedGraphData;
}): React.ReactNode => {
  const dispatch = useDispatch();

  const [generatedGraphs, setGeneratedGraphs] = React.useState<React.ReactNode[]>([]);
  const firstReport = useMemo(() => {
    if (consolidatedReports && Object.keys(consolidatedReports).length) {
      return Object.values(consolidatedReports)[0];
    } else return null;
  }, [consolidatedReports]);

  const { userActiveIndex, setUserActiveIndex, pageLoading } = useContext(DashboardPageContext);

  const { preferences } = useSelector((state: State) => state.user);
  const { dashboardConfiguration } = useSelector((state: State) => state.organization.configuration);
  const isNotInitialRender = useRef(false);

  const currentMonthIndex = useMemo(() => {
    if (firstReport?.data.length) {
      const currentDate = date();
      return firstReport.data.findIndex((item) => isSameMonth(new Date(item.date), currentDate));
    }
    return -1;
  }, [firstReport]);

  const dataKeys = useMemo(() => {
    if (firstReport?.data.length) {
      const applicableKeys = ['date', 'workingModel', 'activeScenario', 'scenario1', 'scenario2', 'scenario3'];
      return Object.keys(firstReport.data[0]).filter((key) => applicableKeys.includes(key));
    } else return [];
  }, [firstReport]);
  const lines: ILineProps[] = [
    { dataKey: 'workingModel', stroke: '#64755C' },
    { dataKey: 'activeScenario', stroke: '#406F83', isDashed: true },
    { dataKey: 'scenario1', stroke: '#EBA61E', isDashed: true },
    { dataKey: 'scenario2', stroke: '#8A6190', isDashed: true },
    { dataKey: 'scenario3', stroke: '#45A59F', isDashed: true },
  ];
  const filteredLines = useMemo(() => {
    return lines.filter((line) => dataKeys.includes(line.dataKey));
  }, [dataKeys, lines]);

  useEffect(() => {
    if (isNotInitialRender.current) {
      if (preferences.defaultGraphStartDate)
        dispatch(reassignSelectedIndexesForNewDate(new Date(preferences.defaultGraphStartDate)));
    } else {
      isNotInitialRender.current = true;
    }
  }, [preferences.defaultGraphStartDate, preferences.defaultGraphEndDate]);

  const graphs = useMemo(() => {
    if (consolidatedReports && Object.keys(consolidatedReports).length) {
      return Object.entries(consolidatedReports)
        .filter(([key]) => dashboardConfiguration.graphs.includes(key))
        .sort(
          ([keyA], [keyB]) => dashboardConfiguration.graphs.indexOf(keyA) - dashboardConfiguration.graphs.indexOf(keyB),
        )
        .map(([key, value]) => {
          const yFormatter = (valueFormat?: IFormattingEnum | null): ((value: number | null) => string) => {
            switch (valueFormat) {
              case IFormattingEnum.Currency:
                return (value: number | null): string => {
                  return formatToLetterAbbreviatedNumber({
                    value: value ?? 0,
                    decimal: getDecimalBasedOnValue(value),
                  });
                };
              case IFormattingEnum.Percent:
                return (value: number | null): string => {
                  if (value === null) return '0';
                  const formattedValue = value.toFixed(2);
                  return formattedValue.replace(/\.?0+$/, '') + '%';
                };
              case IFormattingEnum.Number:
              default:
                return (value: number | null): string => {
                  return formatToLetterAbbreviatedNumber({
                    value: value ?? 0,
                    decimal: getDecimalBasedOnValue(value),
                    showCurrency: false,
                  });
                };
            }
          };
          const graphYFormatter = yFormatter(value.formatting);
          const timeboundGoals = [
            ...value.companyGoals
              .filter((goal) => goal.targetDate)
              .map((goal) => ({
                targetValue: goal.targetValue,
                targetDate: goal.targetDate!,
              })),
          ];
          const nonTimeboundGoals = [
            ...value.companyGoals
              .filter((goal) => !goal.targetDate)
              .map((goal) => ({
                targetValue: goal.targetValue,
              })),
          ];
          const indexData: Record<string, number> =
            userActiveIndex !== -1 || currentMonthIndex !== -1
              ? value.data[userActiveIndex !== -1 ? userActiveIndex : currentMonthIndex] ?? {}
              : {};
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const isActiveScenario = indexData.activeScenario !== undefined;
          const titleFigures = Object.keys(indexData)
            .filter((key) => key !== 'date')
            .map((key) => indexData[key].toString());
          const graphTitle = (
            <GraphTitle
              title={value.title}
              month={
                userActiveIndex !== -1 || currentMonthIndex !== -1
                  ? format(
                      new Date(
                        value.data[userActiveIndex !== -1 ? userActiveIndex : currentMonthIndex]?.date ?? new Date(),
                      ),
                      'MMM',
                    ).toUpperCase()
                  : undefined
              }
              figures={titleFigures}
              yFormatter={graphYFormatter}
              activeScenario={isActiveScenario}
            />
          );

          return (
            <LineGraph
              id={key}
              key={`${key}`}
              data={value.data}
              dataKeys={dataKeys}
              lines={filteredLines}
              card={{
                title: graphTitle,
              }}
              roundTicksToMoney={value.formatting === IFormattingEnum.Currency}
              isDashboardGraph
              yFormatter={graphYFormatter}
              externalActiveIndex={userActiveIndex}
              setExternalActiveIndex={setUserActiveIndex}
              cardClassName="max-h-[300px] min-h-[230px] !h-[18dvw] !rounded-xl !shadow-[0_2px_9px_0_rgba(0,_0,_0,_0.04)]"
              timeboundGoals={timeboundGoals}
              nonTimeboundGoals={nonTimeboundGoals}
            />
          );
        });
    } else return null;
  }, [consolidatedReports, dataKeys, dashboardConfiguration]);

  useEffect(() => {
    if (!dashboardConfiguration.graphs.length) setGeneratedGraphs([]);
    if (!graphs?.length) return;
    const graphsProps = graphs.map((graph) => {
      return ZGraphProps.parse(graph.props);
    });

    if (generatedGraphs.length) {
      const generatedGraphsProps = generatedGraphs.map((graph) => {
        return ZGraphProps.parse((graph as React.ReactElement).props);
      });

      const areGraphsEqual = graphsProps.every((graph, index) => {
        if (!generatedGraphsProps[index]) return false;
        return (
          isEqual(graph.card, generatedGraphsProps[index].card) &&
          isEqual(graph.data, generatedGraphsProps[index].data) &&
          isEqual(graph.lines, generatedGraphsProps[index].lines) &&
          isEqual(graph.dataKeys, generatedGraphsProps[index].dataKeys) &&
          isEqual(graph.externalActiveIndex, generatedGraphsProps[index].externalActiveIndex) &&
          isEqual(graph.cardClassName, generatedGraphsProps[index].cardClassName) &&
          isEqual(graph.timeboundGoals, generatedGraphsProps[index].timeboundGoals) &&
          isEqual(graph.nonTimeboundGoals, generatedGraphsProps[index].nonTimeboundGoals)
        );
      });
      if (!areGraphsEqual || graphsProps.length !== generatedGraphsProps.length) {
        setGeneratedGraphs(graphs);
      }
    } else {
      setGeneratedGraphs(graphs);
    }
  }, [graphs]);

  useEffect(() => {
    if (generatedGraphs.length) {
      setGeneratedGraphs((prev) =>
        prev.map((graph): React.ReactNode => {
          const graphProps = ZGraphProps.parse((graph as React.ReactElement).props);
          const graphTitleProps = ZGraphTitleProps.parse((graphProps.card.title as React.ReactElement).props);
          const indexData: Record<string, number> =
            userActiveIndex !== -1 || currentMonthIndex !== -1
              ? graphProps.data[userActiveIndex !== -1 ? userActiveIndex : currentMonthIndex] ?? {}
              : {};
          const titleFigures = Object.keys(indexData)
            .filter((key) => key !== 'date')
            .map((key) => indexData[key].toString());
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const isActiveScenario = indexData.activeScenario !== undefined;
          const updatedMonth =
            userActiveIndex !== -1 || currentMonthIndex !== -1
              ? format(
                  new Date(
                    graphProps.data[userActiveIndex !== -1 ? userActiveIndex : currentMonthIndex]?.date ?? date(),
                  ),
                  'MMM',
                ).toUpperCase()
              : undefined;

          const graphTitle = (
            <GraphTitle
              title={graphTitleProps.title}
              month={updatedMonth}
              figures={titleFigures}
              yFormatter={graphTitleProps.yFormatter}
              activeScenario={isActiveScenario}
            />
          );

          const newCard = {
            title: graphTitle,
          };

          if (graphProps.externalActiveIndex !== userActiveIndex || !isEqual(graphProps.card, newCard)) {
            return React.cloneElement(graph as React.ReactElement, {
              externalActiveIndex: userActiveIndex,
              setExternalActiveIndex: setUserActiveIndex,
              card: newCard,
            });
          }

          return graph;
        }),
      );
    }
  }, [userActiveIndex, currentMonthIndex]);

  const graphsLoadingSkeleton = (
    <div className="w-full flex flex-col gap-2 max-w-[1000px] -mt-1">
      <Skeleton className={'max-h-[300px] min-h-[230px] !h-[18dvw] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'max-h-[300px] min-h-[230px] !h-[18dvw] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'max-h-[300px] min-h-[230px] !h-[18dvw] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'max-h-[300px] min-h-[230px] !h-[18dvw] w-full rounded-xl'} baseColor="#F8F9F6" />
    </div>
  );

  const emptyGraphs = (
    <Card className="!px-5 !md:px-5 !py-4 !md:py-4 max-h-[300px] min-h-[230px] !h-[18dvw] justify-center items-center !shadow-[0_2px_9px_0_rgba(0,_0,_0,_0.04)]">
      <div className="flex flex-col w-[320px] justify-center text-center">
        <Typography weight="semibold" color="secondary" className="mb-2">
          Dashboard Graphs
        </Typography>
        <Typography color="empty" className="mb-6">
          {`View your financial KPI's over time, easily comparing different
        scenarios and the impact of decisions`}
        </Typography>
        <Typography color="empty">Add these in by editing the dashboard</Typography>
      </div>
    </Card>
  );

  const showLoadingSkeleton = pageLoading || (!generatedGraphs.length && dashboardConfiguration.graphs.length);

  const showEmptyGraphs = !generatedGraphs.length && !showLoadingSkeleton;

  let content;
  if (showLoadingSkeleton && !generatedGraphs.length) {
    content = graphsLoadingSkeleton;
  } else if (showEmptyGraphs) {
    content = emptyGraphs;
  } else if (generatedGraphs.length) {
    content = generatedGraphs;
  } else {
    content = emptyGraphs;
  }

  return <div className="flex flex-col gap-3 h-full w-full">{content}</div>;
};

export default DashboardGraphs;
