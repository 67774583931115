import React, { forwardRef, useContext } from 'react';
import { FormulaBuilderContext } from './FormulaBuilderContext';

const ConstantElement = forwardRef<
  HTMLDivElement,
  {
    formulaIndex?: number;
    renderedValue?: string | number | null;
    selectable?: boolean;
    valid?: boolean;
  }
>(({ formulaIndex, renderedValue, selectable, valid }, ref) => {
  const { inputPosition, setInputPosition, inputRef, value } = useContext(FormulaBuilderContext);
  const onClickFunction = ({
    event,
    newIndex,
  }: {
    event: React.MouseEvent<HTMLDivElement, MouseEvent>;
    newIndex: number;
  }): void => {
    if (selectable) {
      event.stopPropagation();
      setInputPosition(newIndex);
    }
  };
  return (
    <div className="flex flex-row">
      {formulaIndex !== undefined &&
        (inputPosition !== formulaIndex || (inputPosition === formulaIndex && Boolean(value.length))) && (
          <div
            onClick={(e) => {
              selectable && e.stopPropagation();
              if (formulaIndex >= 0 && inputPosition !== formulaIndex) {
                onClickFunction({ event: e, newIndex: formulaIndex });
              } else {
                inputRef.current?.focus();
              }
            }}
            className="w-2 max-w-2 min-w-2 cursor-text"
          />
        )}
      <div
        onClick={(e) => {
          selectable && e.stopPropagation();
          if (formulaIndex !== undefined && formulaIndex >= 0 && inputPosition !== formulaIndex + 1) {
            onClickFunction({ event: e, newIndex: formulaIndex + 1 });
          } else {
            inputRef.current?.focus();
          }
        }}
        className={`border border-transparent ${valid ? '' : '!text-red-500'}`}
        ref={ref}
      >
        {renderedValue}
      </div>
    </div>
  );
});

ConstantElement.displayName = 'ConstantElement';

export default ConstantElement;
