import { z } from 'zod';
import request from '~/utils/request';
import { IFormula, IFormulaGroupSorting, IFormulaSort, ZFormula, ZFormulaSort } from './formulas.types';
import { IAPIResponse } from '~/utils/types';

export const list = async ({
  organizationUuid,
  startDate,
  endDate,
}: {
  organizationUuid: string;
  startDate: Date;
  endDate: Date;
}): Promise<IFormula[]> => {
  const formulas = (await request({
    url: `/formulas`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
    params: {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      includes: ['calculations'],
    },
  })) as IAPIResponse;

  return z.array(ZFormula).parse(formulas.data.data);
};

export const getSortOrder = async ({ organizationUuid }: { organizationUuid: string }): Promise<IFormulaSort> => {
  const sortOrder = (await request({
    url: `/formulas/sorting`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
  })) as IAPIResponse;

  return ZFormulaSort.parse(sortOrder.data.data);
};

export const updateSortOrder = async ({
  organizationUuid,
  scenarioUuid,
  groups,
}: {
  organizationUuid: string;
  scenarioUuid: string;
  groups: IFormulaGroupSorting[];
}): Promise<IFormulaSort> => {
  const data = {
    url: `/formulas/sorting`,
    method: 'PATCH',
    headers: { 'Organization-Uuid': organizationUuid },
    body: {
      groups,
    },
    params: {},
  };

  if (scenarioUuid) {
    data.params = { scenarioUuid };
  }

  const updateSortOrder = (await request({
    ...data,
    method: 'PATCH' as const,
  })) as IAPIResponse;

  return ZFormulaSort.parse(updateSortOrder.data.data);
};
