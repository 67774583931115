import { AxiosResponse } from 'axios';
import request from '~/utils/request';

export const updateScenario = async ({
  organizationUuid,
  scenarioUuid,
  scenarioTitle,
  updateEditModeStart,
}: {
  organizationUuid: string;
  scenarioUuid: string;
  scenarioTitle?: string;
  updateEditModeStart?: boolean;
}): Promise<AxiosResponse> => {
  const response = await request({
    method: 'PATCH',
    url: `/organizations/${organizationUuid}/scenarios/${scenarioUuid}`,
    body: {
      changeDescription: scenarioTitle,
      updateEditModeStart,
    },
  });
  return response;
};

export const cancelScenario = async ({
  organizationUuid,
  scenarioUuid,
}: {
  organizationUuid: string;
  scenarioUuid: string;
}): Promise<AxiosResponse> => {
  const response = await request({
    method: 'PATCH',
    url: `/organizations/${organizationUuid}/scenarios/${scenarioUuid}/cancel`,
    params: {
      scenarioUuid,
    },
  });
  return response;
};

export const restoreScenario = async ({
  organizationUuid,
  scenarioUuid,
}: {
  organizationUuid: string;
  scenarioUuid: string;
}): Promise<AxiosResponse> => {
  const response = await request({
    method: 'PATCH',
    url: `/organizations/${organizationUuid}/scenarios/${scenarioUuid}/restore`,
  });
  return response;
};

export const deleteScenario = async ({
  organizationUuid,
  scenarioUuid,
}: {
  organizationUuid: string;
  scenarioUuid: string;
}): Promise<AxiosResponse> => {
  const response = await request({
    method: 'DELETE',
    url: `/organizations/${organizationUuid}/scenarios/${scenarioUuid}`,
  });
  return response;
};

export const mergeScenarioIntoMain = async ({
  organizationUuid,
  scenarioUuid,
}: {
  organizationUuid: string;
  scenarioUuid: string;
}): Promise<AxiosResponse> => {
  const response = await request({
    method: 'POST',
    url: `/organizations/${organizationUuid}/scenarios/${scenarioUuid}/merge`,
  });
  return response;
};
