import { IFormula } from '~/pages/FinancialModelDeprecated/entity/types';

export const filterFormulaList = ({
  value,
  formulaList,
  inputAttributeTitle,
}: {
  value: string;
  formulaList: IFormula[];
  inputAttributeTitle: string;
}): IFormula[] => {
  if (!value.length) return [];

  const prefilteredList = [...formulaList];
  // Allow Formulas to self reference immediately
  if (inputAttributeTitle) {
    const exists = prefilteredList.some((formula) => formula.recipe.name === inputAttributeTitle);
    if (!exists) {
      // We are mocking the formula object here so it can be selected
      prefilteredList.push({
        uuid: 'self',
        formulaUuid: 'self',
        dataSourceUuid: null,
        organizationUuid: '',
        scenarioUuid: null,
        createdBy: null,
        deletedBy: null,
        createdAt: '',
        deletedAt: null,
        isProtected: false,
        recipe: {
          name: inputAttributeTitle,
          expression: '',
          variables: {},
        },
      });
    }
  }

  const filteredList = prefilteredList.filter((formula) =>
    formula.recipe.name.toLowerCase().includes(value.trim().toLowerCase()),
  );
  return filteredList;
};
