import { IRoundDirectionEnum, IRoundingInstructions } from '~/pages/FinancialModelDeprecated/entity/types';

export const roundCalculatedValue = ({
  value,
  roundingInstructions,
}: {
  value: number;
  roundingInstructions?: IRoundingInstructions;
}): number => {
  if (!roundingInstructions || roundingInstructions.precision === 0) {
    return value;
  }

  const factor = 1 / Math.abs(roundingInstructions.precision);
  let result;

  if (roundingInstructions.direction === IRoundDirectionEnum.Up) {
    result = Math.ceil(value * factor) / factor;
  } else if (roundingInstructions.direction === IRoundDirectionEnum.Down) {
    result = Math.floor(value * factor) / factor;
  } else {
    result = Math.round(value * factor) / factor;
  }

  return result;
};
