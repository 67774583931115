import React, { ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FormulaList from './FormulaList';
import { IFormula, IUpdateCalculationModifier, IUpdateTimeModifier, IVariables } from '../../entity/types';
import { VariableTypeEnum } from '../../entity/schemas';
import Typography from '~/components/Typography';
import validateFormula from '../../utils/validateFormula';
import FormulaBuilderContentEditable from './FormulaBuilderContentEditable';
import { FormulaBuilderContext } from './FormulaBuilderContext';
import { filterFormulaList } from './services/filterFormulaList';
import * as formulaBuilderServices from './services/index';

const FormulaBuilderInput = ({
  formulaUuid,
  formulaList,
  handleUpdateCalculationModifier,
  handleUpdateTimeModifier,
  formulaTitle,
  inputAttributeTitle,
}: {
  formulaUuid?: string;
  formulaList: IFormula[];
  handleUpdateCalculationModifier?: ({
    calculationModifier,
    formulaForUpdate,
    formulaTextValue,
    refToUpdate,
  }: IUpdateCalculationModifier) => void;
  handleUpdateTimeModifier: ({
    timeModifier,
    formulaForUpdate,
    formulaTextValue,
    refToUpdate,
  }: IUpdateTimeModifier) => void;
  formulaTitle?: string;
  inputAttributeTitle: string;
}): ReactElement => {
  const {
    variables,
    setVariables,
    displayFormulaError,
    setDisplayFormulaError,
    updatedFormula,
    setUpdatedFormula,
    value,
    setValue,
    inputPosition,
    setInputPosition,
    setFormula,
    segmentToDelete,
    setSegmentToDelete,
    inputRef,
    setEnteredConstantFrom,
  } = useContext(FormulaBuilderContext);
  const isDisabled = Boolean(
    formulaTitle &&
      [
        'Headcount',
        'New Hires',
        'Headcount Related',
        'Software Expenses',
        'Salary and Wages',
        'Other Expenses',
        'Contract Revenue Recognition',
        'Contract Cash Collection',
        'Contract Setup Fees',
      ].includes(formulaTitle),
  );
  const isContractFormula = Boolean(
    formulaTitle &&
      ['Contract Revenue Recognition', 'Contract Cash Collection', 'Contract Setup Fees'].includes(formulaTitle),
  );

  const prevLengthRef = useRef(updatedFormula.length);

  useEffect(() => {
    if (prevLengthRef.current === 0 && updatedFormula.length > 0) {
      setInputPosition(updatedFormula.length);
    }
    prevLengthRef.current = updatedFormula.length;
  }, [updatedFormula.length]);

  const filteredFormulaList = useMemo(() => {
    return filterFormulaList({
      value,
      formulaList,
      inputAttributeTitle,
    });
  }, [value, formulaList]);

  const [highlightedFormula, setHighlightedFormula] = useState<{
    formula?: IFormula;
    index: number;
  }>({ formula: value.length ? filteredFormulaList[0] : undefined, index: 0 });

  const addOperator = ({
    operator,
    splitValueAtIndex,
    desiredInputIndex,
  }: {
    operator: string | null;
    splitValueAtIndex?: number;
    desiredInputIndex?: number;
  }): void => {
    formulaBuilderServices.handleAddOperator({
      operator,
      splitValueAtIndex,
      desiredInputIndex,
      value,
      setValue,
      inputPosition,
      setInputPosition,
      updatedFormula,
      setUpdatedFormula,
      setFormula,
      variables,
      setVariables,
      inputRef,
    });
  };

  const addAttribute = (selectedFormula: IFormula): void => {
    formulaBuilderServices.handleSelectAttribute({
      selectedFormula,
      formulaTitle,
      updatedFormula,
      setUpdatedFormula,
      setFormula,
      variables,
      setVariables,
      editingFormulaUuid: formulaUuid,
      handleUpdateCalculationModifier,
      handleUpdateTimeModifier,
      inputPosition,
      setInputPosition,
      setValue,
      inputRef,
    });
  };

  const onKeyDown = ({
    e,
    position,
  }: {
    e: React.KeyboardEvent<HTMLDivElement>;
    position?: 'beginning' | 'end';
  }): void => {
    formulaBuilderServices.handleKeyDown({
      event: e,
      position,
      value,
      filteredFormulaList,
      highlightedFormula,
      setHighlightedFormula,
      inputPosition,
      setInputPosition,
      updatedFormula,
      setUpdatedFormula,
      setFormula,
      variables,
      setVariables,
      setValue,
      setEnteredConstantFrom,
      segmentToDelete,
      setSegmentToDelete,
      handleUpdateCalculationModifier,
      handleUpdateTimeModifier,
      formulaTitle,
      editingFormulaUuid: formulaUuid,
      inputRef,
    });
  };

  const onPaste = (e: React.ClipboardEvent<HTMLDivElement>): void => {
    formulaBuilderServices.handlePaste({
      event: e,
      inputPosition,
      setInputPosition,
      updatedFormula,
      setUpdatedFormula,
      setFormula,
      variables,
      setVariables,
      handleUpdateCalculationModifier,
      handleUpdateTimeModifier,
      formulaUuid,
      setValue,
      inputRef,
    });
  };

  const onRemoveSegmentToDelete = (): void => {
    formulaBuilderServices.handleRemoveSegmentToDelete({
      segmentToDelete,
      setSegmentToDelete,
      updatedFormula,
      inputRef,
    });
  };

  useEffect(() => {
    const formulaToValidate = [...updatedFormula];
    let expression = updatedFormula.map((f) => f.textValue).join('');
    const recipeVariables: IVariables = { ...variables };
    if (value.length) {
      expression += `$${Number(Object.keys(variables).length) + 1}`;
      formulaToValidate.push({
        element: <span key={value}>{value}</span>,
        ref: null,
        textValue: `$${Number(Object.keys(variables).length) + 1}`,
        type: 'constant',
      });
      recipeVariables[`$${Number(Object.keys(variables).length) + 1}`] = {
        type: VariableTypeEnum.Constant,
        constantValue: Number(value),
        formulaUuid: null,
        timeModifier: {},
        calculationType: null,
      };
    }
    const { validated } = validateFormula({
      formulaToValidate: formulaToValidate,
      formulaList: formulaList,
      expression,
      recipeVariables,
    });
    if (validated) {
      setDisplayFormulaError({
        isDisplayed: false,
        message: '',
      });
    }
  }, [value, updatedFormula, variables]);

  useEffect(() => {
    if (filteredFormulaList.length) {
      setHighlightedFormula({
        formula: filteredFormulaList[0],
        index: 0,
      });
    } else {
      setHighlightedFormula({ formula: undefined, index: 0 });
    }
  }, [filteredFormulaList]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent): void => {
      if (value.length) {
        addOperator({ operator: null });
      }
      if (segmentToDelete?.segmentRef.current) {
        const clickedElement = e.target as Node;
        if (
          !updatedFormula[segmentToDelete.segmentIndex].ref.current?.contains(clickedElement) &&
          !inputRef.current?.contains(clickedElement)
        ) {
          onRemoveSegmentToDelete();
        }
      }
      if (inputRef.current && !segmentToDelete) {
        inputRef.current.focus();
      }
    };
    if (Boolean(segmentToDelete) || value.length) {
      document.addEventListener('mouseup', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [segmentToDelete, updatedFormula, value]);

  useEffect(() => {
    // handles keyboard events when a variable is selected
    const handleKeyDown = (e: KeyboardEvent): void => {
      formulaBuilderServices.handleKeyDownWithSelectedVariable({
        event: e as unknown as React.KeyboardEvent<HTMLDivElement>,
        filteredFormulaList,
        segmentToDelete,
        setSegmentToDelete,
        inputPosition,
        setInputPosition,
        updatedFormula,
        setUpdatedFormula,
        setFormula,
        variables,
        setVariables,
        value,
        setValue,
        setEnteredConstantFrom,
        setHighlightedFormula,
        inputRef,
      });
    };

    if (Boolean(segmentToDelete) || value.length) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [segmentToDelete, updatedFormula, variables, value]);

  const renderContentEditable = ({
    positionCheck,
    leftPadding,
    rightPadding,
    isInputDisabled,
  }: {
    positionCheck: boolean;
    leftPadding?: boolean;
    rightPadding?: boolean;
    isInputDisabled?: boolean;
  }): React.ReactNode | null => {
    return positionCheck ? (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`relative ${rightPadding ? 'mr-2' : ''} ${leftPadding ? 'ml-2' : ''} ${updatedFormula.length === 0 && 'flex-grow'}`}
        data-testid="content-editable-parent"
      >
        <FormulaBuilderContentEditable
          handleKeyDown={onKeyDown}
          handleAddOperator={addOperator}
          ref={inputRef}
          isDisabled={isInputDisabled}
        />
        <FormulaList
          value={value.trim()}
          onSelectAttribute={addAttribute}
          formulaList={filteredFormulaList}
          highlightedFormula={highlightedFormula}
          setHighlightedFormula={setHighlightedFormula}
        />
      </div>
    ) : null;
  };

  return (
    <div className="flex flex-col">
      <Typography size="xs" className="mb-1">
        Formula
      </Typography>
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (value.length) {
            addOperator({ operator: null, desiredInputIndex: inputPosition });
          } else {
            if (updatedFormula.length) {
              setInputPosition(updatedFormula.length);
              if (inputRef.current) {
                inputRef.current.focus();
              }
            } else {
              setInputPosition(0);
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }
          }
          onRemoveSegmentToDelete();
        }}
        onPaste={onPaste}
        className={`relative pl-[0.57rem] py-[5px] flex flex-wrap gap-y-1 items-center border cursor-text ${
          displayFormulaError.isDisplayed ? 'border-red-300' : 'border-gray-300'
        } ${isDisabled ? 'text-neutral-200 pointer-events-none' : ''} rounded`}
        data-testid="formula-builder-input"
      >
        =
        {renderContentEditable({
          positionCheck: inputPosition === 0 || updatedFormula.length === 0,
          leftPadding: true,
          isInputDisabled: isDisabled,
        })}
        {isContractFormula ? (
          <div className="flex flex-row text-nowrap border border-neutral-100 focus:border-green-400 rounded-full bg-neutral-15 px-3 ml-2 py-0.5">
            <div>{formulaTitle}</div>
          </div>
        ) : (
          updatedFormula.map((f, index) => (
            <div key={f.element.key} className="flex items-center">
              {f.element}
              {index === inputPosition - 1 &&
                inputPosition !== updatedFormula.length &&
                renderContentEditable({
                  positionCheck: true,
                  leftPadding: f.type !== 'operator' || Boolean(value.length),
                  rightPadding: f.type === 'operator' && !value.length,
                  isInputDisabled: isDisabled,
                })}
            </div>
          ))
        )}
        {renderContentEditable({
          positionCheck: inputPosition === updatedFormula.length && updatedFormula.length > 0,
          leftPadding: true,
          isInputDisabled: isDisabled,
        })}
        {isDisabled && <div className="absolute right-0 bg-neutral-50 opacity-45 w-full h-full pointer-events-none" />}
      </div>
      {displayFormulaError.isDisplayed && (
        <p className="text-red-500 text-xs italic p-1">{displayFormulaError.message}</p>
      )}
    </div>
  );
};

export default FormulaBuilderInput;
