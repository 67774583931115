import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Typography from '../Typography';

interface IProps {
  id: string;
  state: Types.InputState;
  setState: React.Dispatch<React.SetStateAction<Types.InputState>>;
  className?: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
}

const ContentEditableInput = ({
  id,
  className = '',
  state,
  setState,
  onBlur = (): void => {},
  onKeyDown,
  disabled,
}: IProps): ReactElement => {
  const [value, setValue] = useState(state.value);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setValue(state.value);
  }, [state.value]);

  const saveCursorPosition = (): number | null => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return null;
    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(divRef.current!);
    preCaretRange.setEnd(range.startContainer, range.startOffset);
    const cursorPosition = preCaretRange.toString().length;
    return cursorPosition;
  };

  const restoreCursorPosition = (cursorPosition: number): void => {
    const selection = window.getSelection();
    const range = document.createRange();
    let charCount = 0;
    const span = divRef.current;
    if (span) {
      for (const node of span.childNodes) {
        if (node.nodeType === Node.TEXT_NODE) {
          const textLength = node.textContent!.length;
          if (charCount + textLength >= cursorPosition) {
            range.setStart(node, cursorPosition - charCount);
            range.setEnd(node, cursorPosition - charCount);
            break;
          }
          charCount += textLength;
        }
      }
    }
    selection!.removeAllRanges();
    selection!.addRange(range);
  };

  const handleOnBlur = (e: React.FormEvent<HTMLDivElement>): void => {
    const newValue = e.currentTarget.textContent ?? '';
    setState((prevState) => ({
      ...prevState,
      touched: true,
      pristine: false,
      valid: prevState.validation.test(newValue),
    }));
    onBlur();
  };

  const handleOnInput = (e: React.FormEvent<HTMLDivElement>): void => {
    const cursorPosition = saveCursorPosition();
    let newValue = e.currentTarget.textContent ?? '';

    if (newValue === '') {
      newValue = '\u00A0'; // Non-breaking space to prevent cursor from disappearing
    }

    setValue(newValue);
    setState((prevState) => ({
      ...prevState,
      value: newValue,
      valid: prevState.validation.test(newValue),
    }));
    if (cursorPosition !== null) {
      setTimeout(() => restoreCursorPosition(cursorPosition), 0);
    }
  };

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (e.target === divRef.current) {
      divRef.current.focus();
    }
  };

  const showError = !state.valid && state.touched && !state.pristine;
  const focusStyles = ` hover:border-neutral-100 focus:border-green-500 focus-visible:border-green-500 focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 cursor-text `;
  let spanClassName = ` rounded-md border border-transparent flex items-center px-2 leading-6 text-sm`;
  if (className) spanClassName += ` ${className}`;
  if (showError) spanClassName += ' border-red-300 ';
  if (disabled) {
    spanClassName += ' text-neutral-100 ';
  } else {
    spanClassName += focusStyles;
  }

  return (
    <div>
      <div
        ref={divRef}
        id={id}
        role="textbox"
        contentEditable={!disabled}
        onInput={handleOnInput}
        onBlur={handleOnBlur}
        onClick={handleOnClick}
        onKeyDown={onKeyDown}
        className={spanClassName}
        suppressContentEditableWarning
      >
        {/* TODO - Add placeholder */}
        {value}
      </div>
      {state.errorMessage && showError && (
        <Typography className="px-2 italic" size="sm" color="warning">
          {state.errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default ContentEditableInput;
