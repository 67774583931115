import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import LoadingSpinner from '../Loader';

const FullPageLoading = ({
  text,
  color = 'blue',
  isVisible,
  opacity = '70',
  size = 'size-[50px]',
}: {
  text: string;
  color?: 'blue' | 'green';
  isVisible: boolean;
  opacity?: string;
  size?: string;
}): React.ReactElement => {
  const [visible, setVisible] = useState(isVisible);
  const [shouldRender, setShouldRender] = useState(isVisible);
  const fill = color === 'blue' ? '#5A8496' : '#7F9376';

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
      setTimeout(() => setVisible(true), 10);
    } else {
      setVisible(false);
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  return (
    <div
      className={`w-screen h-screen fixed top-0 left-0 z-40 flex items-center justify-center transition-opacity duration-300 ${
        visible ? 'opacity-100' : 'opacity-0'
      } ${!shouldRender && 'hidden'}`}
    >
      <div className={`absolute top-0 left-0 w-full h-full bg-white opacity-${opacity}`} />
      <div className="relative flex flex-col justify-center items-center gap-2">
        <LoadingSpinner size={size} color={fill} />
        <Typography color={color} size="sm" weight="bold">
          {text}
        </Typography>
      </div>
    </div>
  );
};

export default FullPageLoading;
