import React, { Fragment, useRef, useState } from 'react';
import { Popover, PopoverButton, PopoverPanel, Transition, PopoverPanelProps } from '@headlessui/react';

const HoverPopover = ({
  buttonContent,
  panelContent,
  anchor = 'bottom',
  panelClassName,
  buttonClassName,
}: {
  buttonContent: React.ReactNode;
  panelContent: React.ReactNode;
  anchor?: PopoverPanelProps['anchor'];
  panelClassName?: string;
  buttonClassName?: string;
}): React.ReactNode => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [openState, setOpenState] = useState<boolean>(false);

  const toggleMenu = (action: 'onMouseEnter' | 'onMouseLeave'): void => {
    setOpenState(action === 'onMouseEnter');
  };

  return (
    <Popover className="relative w-fit">
      <div
        className="flex"
        onMouseEnter={() => toggleMenu('onMouseEnter')}
        onMouseLeave={() => toggleMenu('onMouseLeave')}
      >
        <PopoverButton ref={buttonRef} className={`outline-none border-none ${buttonClassName}`}>
          <div>{buttonContent}</div>
        </PopoverButton>
        <Transition
          show={openState}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <PopoverPanel anchor={anchor} className={`z-10 ${panelClassName && panelClassName}`}>
            {panelContent}
          </PopoverPanel>
        </Transition>
      </div>
    </Popover>
  );
};

export default HoverPopover;
