import React from 'react';
import Typography from '~/components/Typography';

const getIndexColor = (
  index: number,
  activeScenario: boolean,
): 'green' | 'orange' | 'turquoise' | 'purple' | 'blue' => {
  if (index === 0) return 'green';
  if (activeScenario && index === 1) return 'blue';
  if (activeScenario) {
    index -= 1;
  }
  if (index === 1) return 'orange';
  if (index === 2) return 'purple';
  if (index === 3) return 'turquoise';
  return 'green';
};

const GraphTitle = ({
  title,
  month,
  figures,
  yFormatter,
  activeScenario,
}: {
  title: string;
  month?: string;
  figures?: string[];
  yFormatter: (value: number) => string;
  activeScenario: boolean;
}): React.ReactElement => {
  return (
    <div className="flex items-center justify-between w-full">
      <Typography weight="medium" className="w-full">
        {title}
      </Typography>
      {month && figures && (
        <div className="flex items-center" data-testid={`${title}-month-value-chart`}>
          <Typography size="xs" color="disabled" weight="thin" className="mr-2">
            {month}
          </Typography>
          {figures.map((figure, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="flex items-center" key={`${index}-${figure}`}>
              <Typography size="sm" weight="semibold" color={getIndexColor(index, activeScenario)}>
                {yFormatter(Number(figure))}
              </Typography>
              {index < figures.length - 1 && (
                <Typography size="xs" color="disabled" weight="thin" className="mx-2">
                  |
                </Typography>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GraphTitle;
