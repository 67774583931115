import React, { useContext, useEffect } from 'react';
import Modal from '~/components/Modal';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import ExpenseForm from './CreateExpenseForm';
import useExpenseFormState from '~/pages/Expenses/components/Expenses/createExpense/useExpenseFormState';
import createExpense from '~/pages/Expenses/components/Expenses/createExpense/createExpense';
import { ExpensesPageContext } from '~/pages/Expenses/context/ExpensesContext';
import toast from 'react-hot-toast';
import useKeyPress from '~/utils/hooks/useKeyPress';
import request from '~/utils/request';
import { ZExpense } from '~/pages/Expenses/components/Expenses/entity/schemas';
import { z } from 'zod';
import { IExpense } from '../types';

const CreateExpenseModal = (): React.ReactNode => {
  const {
    expenses,
    setExpenses,
    expenseModal,
    setExpenseModal,
    expenseUuid,
    setExpenseUuid,
    revalidateFilteredExpensesReport,
    reload,
  } = useContext(ExpensesPageContext);
  const formState = useExpenseFormState();
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);

  useKeyPress('Escape', () => {
    setExpenseModal(false);
  });

  useEffect(() => {
    const getExpenseDetails = async (): Promise<void> => {
      if (!expenseUuid) return;
      const fetchExpenseResponse = await request({
        url: `/expenses/${expenseUuid.expenseUuid}`,
        headers: { 'Organization-Uuid': organizationUuid },
        params: { scenarioUuid: activeScenarioUuid },
        method: 'GET',
      });
      if (fetchExpenseResponse.status >= 400) return;

      const parsedResponse = z
        .object({
          data: z.object({ data: ZExpense }),
        })
        .parse(fetchExpenseResponse);

      formState.setFormStateFromIExpense({
        expense: parsedResponse.data.data,
      });
    };

    if (expenseUuid) {
      getExpenseDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseUuid]);

  return (
    <Modal
      id={expenseUuid && expenseUuid.type === 'edit' ? 'edit-expense-modal' : 'create-expense-modal'}
      isOpen={expenseModal}
      title={expenseUuid && expenseUuid.type === 'edit' ? 'Edit Expense' : 'New Expense'}
      size="sm"
      onClose={() => {
        setExpenseModal(false);
        if (expenseUuid) {
          formState.resetFormState();
          setExpenseUuid(undefined);
        }
      }}
    >
      <div data-testid="create-expense-modal-container" className="mt-2 w-full">
        <ExpenseForm
          onClose={() => {
            setExpenseModal(false);
            if (expenseUuid) {
              formState.resetFormState();
              setExpenseUuid(undefined);
            }
          }}
          formState={formState}
          createExpense={async () => {
            await createExpense({
              organizationUuid,
              scenarioUuid: activeScenarioUuid,
              expenseUuid: expenseUuid,
              type:
                formState.type === 'setCost' ? formState.type : formState.headcountDriverType.selected?.value ?? null,
              name: formState.name.value,
              category: formState.category.selected?.value ?? null,
              frequency: formState.frequency.selected?.value ?? null,
              amount:
                formState.headcountDriverType.selected?.value === 'headcountPercentCompensation'
                  ? formState.percentage.value
                  : formState.amount.value,
              departments: formState.departments.selected
                ? formState.departments.selected.map((department) => department.value)
                : null,
              startDate: formState.startDate.startDate,
              endDate: formState.endDate.endDate,
              successCallback: ({ createdExpense }: { createdExpense: IExpense }) => {
                setExpenseModal(false);
                setExpenseUuid(undefined);
                if (expenseUuid && expenseUuid.type === 'edit') {
                  const expenseIndex = expenses.findIndex((expense) => expense.uuid === expenseUuid.uuid);
                  if (expenseIndex !== -1) {
                    const updatedExpenses = [...expenses];
                    updatedExpenses[expenseIndex] = createdExpense;
                    setExpenses(updatedExpenses);
                  } else {
                    reload();
                  }
                } else {
                  setExpenses([...expenses, createdExpense]);
                }
                revalidateFilteredExpensesReport();
                formState.resetFormState();
                toast.success('Expense created');
              },
              failureCallback: () => {
                toast.error('Failed to create expense');
              },
              validateFormState: formState.validateFormState,
            });
          }}
        />
      </div>
    </Modal>
  );
};

export default CreateExpenseModal;
