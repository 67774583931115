import React from 'react';
import { IDraggableGraphPoint } from '../utils/types';
import { Circle, Line } from 'react-konva';
import { invertYValue } from '../utils/invertYValue';
import { KonvaEventObject } from 'konva/lib/Node';
import { IConsolidatedGraphBody } from '~/pages/Dashboard/entity/types';
import { getSnappingBoundries } from '../utils/getSnappingBoundries';

interface IProps {
  graphPoints: {
    activeScenario: IDraggableGraphPoint[];
    workingModel: IDraggableGraphPoint[];
  };
  index: number;
  hoverIndex: number;
  hoveredCircleIndex: number;
  point: IDraggableGraphPoint;
  setHoveredCircleIndex: (index: number) => void;
  handleDragStart: (e: KonvaEventObject<DragEvent>) => void;
  handleDragMove: (e: KonvaEventObject<DragEvent>) => void;
  handleDragEnd: (e: KonvaEventObject<DragEvent>) => void;
  multipliers: { xMultiplier: number; yMultiplier: number };
  yMax: number;
  yMin: number;
  draggingValue: number | null;
  monthHasScenarioOverrideOrActual: boolean[];
  dragEscaped: boolean;
  consolidatedGraphData: IConsolidatedGraphBody;
  externalActiveIndex?: number;
  inputIndex: number | null;
  setInputData: (data: { x: number | null; y: number | null; index: number | null }) => void;
}

const DraggableGraphCircle = ({
  graphPoints,
  index,
  hoverIndex,
  hoveredCircleIndex,
  point,
  setHoveredCircleIndex,
  handleDragStart,
  handleDragMove,
  handleDragEnd,
  multipliers,
  yMax,
  yMin,
  draggingValue,
  monthHasScenarioOverrideOrActual,
  dragEscaped,
  consolidatedGraphData,
  externalActiveIndex,
  inputIndex,
  setInputData,
}: IProps): React.ReactElement => {
  const getStrokeColor = ({
    fill,
    matchesWorkingModel,
    isIndexHovered,
    isCircleHovered,
    matchesInputIndex,
  }: {
    fill: string;
    matchesWorkingModel: boolean;
    isIndexHovered: boolean;
    isCircleHovered: boolean;
    matchesInputIndex: boolean;
  }): 'black' | '#5A8496' | '#FAFAFA' => {
    if (matchesInputIndex) return '#5A8496';
    if (!matchesWorkingModel || !isIndexHovered || isCircleHovered) {
      return fill === 'white' ? '#5A8496' : '#FAFAFA';
    }
    return 'black';
  };

  const matchesWorkingModel =
    graphPoints.workingModel[index].y === point.y &&
    (!point.isDragging ||
      invertYValue({
        value: draggingValue ?? 0,
        maxY: yMax,
        yMultiplier: multipliers.yMultiplier,
      }) === point.y);
  const matchesInputIndex = index === inputIndex;
  const isIndexHovered = index === hoverIndex || matchesInputIndex;
  const isCircleHovered = index === hoveredCircleIndex;
  const fill = isIndexHovered || point.isDragging ? 'white' : '#5A8496';
  const stroke = getStrokeColor({
    fill,
    matchesWorkingModel,
    isIndexHovered,
    isCircleHovered,
    matchesInputIndex,
  });

  const invertedDraggingValue =
    draggingValue !== null
      ? invertYValue({ value: draggingValue, maxY: yMax, yMultiplier: multipliers.yMultiplier })
      : null;
  const opacity =
    point.isDragging || (isIndexHovered && draggingValue === null) || monthHasScenarioOverrideOrActual[index] ? 1 : 0;

  if (externalActiveIndex && externalActiveIndex !== -1 && index === externalActiveIndex && hoverIndex === -1) {
    return <Line points={[point.x, point.y - 7, point.x, point.y + 7]} stroke="#BCBCBC" strokeWidth={1.5} />;
  }

  return (
    <Circle
      key={point.id}
      id={point.id}
      x={point.x}
      y={hoverIndex === index && invertedDraggingValue !== null ? invertedDraggingValue : point.y}
      radius={stroke === '#FAFAFA' ? 6 : 5}
      stroke={stroke}
      strokeWidth={2}
      fill={fill}
      draggable={!dragEscaped && index !== draggingValue}
      opacity={opacity}
      onMouseEnter={(e) => {
        const container = e.target.getStage()?.container();
        setHoveredCircleIndex(index);
        if (container) {
          container.style.cursor = 'pointer';
        }
      }}
      onMouseLeave={(e) => {
        const container = e.target.getStage()?.container();
        setHoveredCircleIndex(-1);
        if (container) {
          container.style.cursor = 'default';
        }
      }}
      onMouseDown={(e) => {
        const container = e.target.getStage()?.container();
        if (container) {
          container.style.cursor = 'grabbing';
        }
      }}
      onMouseUp={(e) => {
        const container = e.target.getStage()?.container();
        if (container) {
          container.style.cursor = 'pointer';
        }
      }}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      dragBoundFunc={(pos) => {
        const boundedY = Math.max(
          Math.min(pos.y, multipliers.yMultiplier * (yMax - yMin)) + 1,
          multipliers.yMultiplier * yMin - 1,
        );

        const inSnappingRange = getSnappingBoundries({
          graphValue: boundedY,
          yMultiplier: multipliers.yMultiplier,
          maxY: yMax,
          minY: yMin,
          activeScenarioValue: consolidatedGraphData.data[index].activeScenario,
          workingModelValue: consolidatedGraphData.data[index].workingModel,
        });

        if (inSnappingRange !== null) {
          return {
            x: point.x,
            y: inSnappingRange,
          };
        }

        return {
          x: point.x,
          y: boundedY,
        };
      }}
      onClick={(e: KonvaEventObject<MouseEvent>) => {
        const X_OFFSET = 50; // half of the width of the input component
        const Y_OFFSET = 58;
        setInputData({ x: e.evt.x - X_OFFSET, y: e.evt.y - Y_OFFSET, index });
      }}
    />
  );
};

export default DraggableGraphCircle;
