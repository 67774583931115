import React from 'react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { useDroppable } from '@dnd-kit/core';

const GroupRow = ({
  isOpen,
  name,
  toggleGroupCollapse,
  numberOfChildren,
  openNewAttribute,
  isDraggingOver,
}: {
  isOpen: boolean;
  name: string;
  toggleGroupCollapse: (name: string) => void;
  numberOfChildren: number;
  openNewAttribute: () => void;
  isDraggingOver: boolean;
}): React.ReactNode => {
  const { setNodeRef } = useDroppable({
    id: name,
  });

  return (
    <div
      ref={setNodeRef}
      className={`mt-1.5 border-t border-neutral-50 w-full flex items-center group gap-5 border-b pl-3 py-[9px] ${
        isDraggingOver ? (isOpen ? 'border-b-green' : 'bg-green-50') : 'bg-white'
      }`}
    >
      <button
        className="flex items-center bg-neutral-25 rounded-full py-[5px] px-3 hover:bg-green-25 hover:text-green-500 gap-1"
        onClick={() => toggleGroupCollapse(name)}
      >
        <ChevronUpIcon className={`text-neutral-300 stroke-2 size-4 ${isOpen ? 'rotate-180' : 'rotate-90'}`} />
        <Typography size="xs" weight="medium" color="secondary">
          {name}
        </Typography>
        <div className="flex items-center justify-center">
          <Typography size="xs" color="lightGray" className="!no-underline">
            {numberOfChildren}
          </Typography>
        </div>
      </button>
      <Button fill="clear" className="!w-auto hidden group-hover:block !p-0" onClick={openNewAttribute}>
        New Attribute
      </Button>
    </div>
  );
};

export default GroupRow;
