/* eslint-disable security/detect-unsafe-regex */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import useInput from '~/components/Input/useInput';
import date from '~/utils/dates/date';
import InputState = Types.InputState;
import { RadioInputState } from '~/components/RadioInput/RadioInput.types';
import { useRadioInput } from '~/components/RadioInput';
import { useSelectMultiple } from '~/components/SelectMultiple';
import usePeriodPicker, { IPeriodPickerState } from '~/components/PeriodPicker/usePeriodPicker';
import { endOfMonth, parseISO, startOfMonth } from 'date-fns';
import { SelectMultipleState } from '~/components/SelectMultiple/SelectMultiple.types';
import {
  CurrencyDollarIcon,
  ComputerDesktopIcon,
  UserIcon,
  CalendarDaysIcon,
  BanknotesIcon,
  Squares2X2Icon,
  CalendarIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import { useSelect } from '~/components/Select';
import { SelectState } from '~/components/Select/Select.types';
import { IExpense } from '~/pages/Expenses/components/Expenses/types';
import { getHeadcountDriverTypeSelected } from '~/pages/Expenses/utils/getHeadcountDriverTypeSelected';
import { getDepartmentsSelected } from '~/pages/Expenses/utils/getDepartmentsSelected';

export type ExpenseType = 'setCost' | 'headcountDriven';

export interface IExpenseFormState {
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  organizationUuid: string;
  type: ExpenseType;
  setType: React.Dispatch<React.SetStateAction<ExpenseType>>;
  name: InputState;
  setName: React.Dispatch<React.SetStateAction<InputState>>;
  category: RadioInputState;
  setCategory: React.Dispatch<React.SetStateAction<RadioInputState>>;
  frequency: RadioInputState;
  setFrequency: React.Dispatch<React.SetStateAction<RadioInputState>>;
  headcountDriverType: SelectState;
  setHeadcountDriverType: React.Dispatch<React.SetStateAction<SelectState>>;
  amount: InputState;
  setAmount: React.Dispatch<React.SetStateAction<InputState>>;
  percentage: InputState;
  setPercentage: React.Dispatch<React.SetStateAction<InputState>>;
  departments: SelectMultipleState;
  setDepartments: React.Dispatch<React.SetStateAction<SelectMultipleState>>;
  startDate: IPeriodPickerState;
  setStartDate: React.Dispatch<React.SetStateAction<IPeriodPickerState>>;
  endDate: IPeriodPickerState;
  setEndDate: React.Dispatch<React.SetStateAction<IPeriodPickerState>>;
  resetFormState: () => void;
  validateFormState: () => void;
  setFormStateFromIExpense: ({ expense }: { expense: IExpense }) => void;
}

const getFrequencyOptions = (type: ExpenseType): RadioInputState['options'] => {
  return type !== 'headcountDriven'
    ? [
        {
          label: 'Monthly',
          value: 'monthly',
          icon: <CalendarDaysIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />,
        },
        {
          label: 'Quarterly',
          value: 'quarterly',
          icon: <Squares2X2Icon className={`flex-shrink-0 size-5`} aria-hidden="true" />,
        },
        {
          label: 'Annually',
          value: 'annually',
          icon: <CalendarIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />,
        },
        {
          label: 'One-time',
          value: 'oneTime',
          icon: <BanknotesIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />,
        },
      ]
    : [
        {
          label: 'Monthly',
          value: 'monthly',
          icon: <CalendarDaysIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />,
        },
        {
          label: 'Quarterly',
          value: 'quarterly',
          icon: <Squares2X2Icon className={`flex-shrink-0 size-5`} aria-hidden="true" />,
        },
        {
          label: 'Annually',
          value: 'annually',
          icon: <CalendarIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />,
        },
        {
          label: 'One-time',
          value: 'oneTime',
          icon: <BanknotesIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />,
        },
        {
          label: 'On-hire',
          value: 'onHire',
          icon: <UserIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />,
        },
      ];
};

const useExpenseFormState = (): IExpenseFormState => {
  const [errorMessage, setErrorMessage] = useState('');
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const [type, setType] = useState<ExpenseType>('setCost');
  const [name, setName] = useInput({
    validation: /^.*$/,
  });
  const [category, setCategory] = useRadioInput({
    options: [
      {
        label: 'Headcount Related',
        value: 'Headcount Related',
        icon: <UserIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />,
      },
      {
        label: 'Software',
        value: 'Software',
        icon: <ComputerDesktopIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />,
      },
      {
        label: 'Other',
        value: 'Other',
        icon: <CurrencyDollarIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />,
      },
    ],
    selected: undefined,
    errorMessage: 'Category is required',
  });
  const [frequency, setFrequency] = useRadioInput({
    options: [],
    selected: { label: 'Monthly', value: 'monthly' },
    errorMessage: 'Frequency is required',
  });

  useEffect(() => {
    if (frequency.selected?.value === 'onHire' && type === 'setCost') {
      setFrequency((prevState) => ({
        ...prevState,
        selected: undefined,
        options: getFrequencyOptions(type),
      }));
    } else {
      setFrequency((prevState) => ({
        ...prevState,
        options: getFrequencyOptions(type),
      }));
    }
  }, [type, setFrequency, frequency.selected?.value]);

  const [headcountDriverType, setHeadcountDriverType] = useSelect({
    options: [
      {
        label: 'Dollar Amount per Employee',
        value: 'headcountFixed',
      },
      {
        label: 'Percent of Monthly Compensation',
        value: 'headcountPercentCompensation',
      },
    ],
    selected: {
      label: 'Dollar Amount per Employee',
      value: 'headcountFixed',
    },
    isNullable: true,
  });
  const [amount, setAmount] = useInput({
    validation: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: 'Amount is required',
  });
  const [percentage, setPercentage] = useInput({
    validation: /^(100(\.0{1,2})?|[0-9]{1,2}(\.\d{1,2})?)$/,
    errorMessage: 'Percentage is required and must be between 0.00 and 100.00',
  });
  const [departments, setDepartments] = useSelectMultiple({
    multiple: true,
  });
  const [startDate, setStartDate] = usePeriodPicker({
    startDate: startOfMonth(date()),
    endDate: endOfMonth(date()),
    mode: 'month',
  });
  const [endDate, setEndDate] = usePeriodPicker({
    startDate: null,
    endDate: null,
    mode: 'month',
  });

  const setFormStateFromIExpense = async ({ expense }: { expense: IExpense }): Promise<void> => {
    const setNewType = ['headcountFixed', 'headcountPercentCompensation'].includes(expense.context.driver)
      ? 'headcountDriven'
      : 'setCost';

    setType(setNewType);

    setName((prevState) => ({
      ...prevState,
      value: expense.name,
      valid: true,
      pristine: false,
      touched: true,
    }));
    setCategory((prevState) => ({
      ...prevState,
      selected: {
        label: expense.context.tag,
        value: expense.context.tag,
      },
      valid: true,
      pristine: false,
      touched: true,
    }));
    setFrequency((prevState) => ({
      ...prevState,
      selected: getFrequencyOptions(setNewType).find((option) => option.value === expense.context.frequency),
      valid: true,
      pristine: false,
      touched: true,
      options: getFrequencyOptions(setNewType),
    }));
    setHeadcountDriverType((prevState) => ({
      ...prevState,
      selected: getHeadcountDriverTypeSelected({
        driver: expense.context.driver,
      }),
      valid: true,
      pristine: false,
      touched: true,
    }));
    setAmount((prevState) => ({
      ...prevState,
      value: expense.context.driver !== 'headcountPercentCompensation' ? (expense.context.amount / 100).toString() : '',
      valid: true,
      pristine: false,
      touched: true,
    }));
    setPercentage((prevState) => ({
      ...prevState,
      value:
        expense.context.driver === 'headcountPercentCompensation' ? (expense.context.amount / 100).toPrecision(4) : '',
      valid: true,
      pristine: false,
      touched: true,
    }));
    const selectedDepartments = await getDepartmentsSelected({
      departmentUuids: expense.context.departments,
    });
    setDepartments((prevState) => ({
      ...prevState,
      selected: selectedDepartments,
      valid: true,
      pristine: false,
      touched: true,
    }));
    setStartDate({
      ...startDate,
      startDate: parseISO(expense.context.startDate),
      endDate: parseISO(endOfMonth(expense.context.startDate).toISOString()),
    });

    const updatedEndDate = expense.context.endDate ? new Date(expense.context.endDate) : null;
    setEndDate({
      ...endDate,
      startDate: updatedEndDate,
      endDate: updatedEndDate,
    });
  };

  const validateFormState = (): void => {
    if (!name.valid) {
      setName((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
    }
    if (!category.selected) {
      setCategory((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
    }
    if (!frequency.selected) {
      setFrequency((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
        valid: false,
      }));
    }
    if (!amount.valid && headcountDriverType.selected?.value !== 'headcountPercentCompensation') {
      setAmount((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
    }
    if (!percentage.valid && headcountDriverType.selected?.value === 'headcountPercentCompensation') {
      setPercentage((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
    }
    setDepartments((prevState) => ({
      ...prevState,
      touched: true,
      pristine: false,
      valid: Boolean(departments.selected && departments.selected.length > 0),
    }));
  };

  const resetFormState = (): void => {
    setType('setCost');
    setName({
      ...name,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setCategory({
      ...category,
      selected: undefined,
      valid: false,
      pristine: true,
      touched: false,
    });
    setFrequency({
      ...frequency,
      selected: undefined,
      valid: false,
      pristine: true,
      touched: false,
    });
    setHeadcountDriverType({
      ...headcountDriverType,
      selected: {
        label: 'Dollar Amount per Employee',
        value: 'headcountFixed',
      },
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setAmount({
      ...amount,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setPercentage({
      ...percentage,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setDepartments({
      ...departments,
      selected: undefined,
      valid: false,
      pristine: true,
      touched: false,
    });
    setStartDate({
      ...startDate,
      startDate: startOfMonth(date()),
      endDate: endOfMonth(date()),
    });
    setEndDate({
      ...endDate,
      startDate: null,
      endDate: null,
    });
  };

  return {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    type,
    setType,
    name,
    setName,
    category,
    setCategory,
    frequency,
    setFrequency,
    headcountDriverType,
    setHeadcountDriverType,
    amount,
    setAmount,
    percentage,
    setPercentage,
    departments,
    setDepartments,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    resetFormState,
    validateFormState,
    setFormStateFromIExpense,
  };
};

export default useExpenseFormState;
