import { isBefore, isSameMonth } from 'date-fns';
import { IConsolidatedGraphBody } from '~/pages/Dashboard/entity/types';
import date from '~/utils/dates/date';
import { roundCalculatedValue } from './roundCalculatedValue';

export const generateOverridesAndActuals = ({
  value,
  changeDate,
  consolidatedGraphData,
  activeScenarioUuid,
}: {
  value: number;
  changeDate: Date;
  consolidatedGraphData: IConsolidatedGraphBody;
  activeScenarioUuid: string | null;
}): {
  overrides: {
    value: number;
    date: Date;
  }[];
  actuals: {
    value: number;
    date: Date;
  }[];
} => {
  let overrides: { date: Date; value: number }[] = [];
  let actuals: { date: Date; value: number }[] = [];
  if (
    activeScenarioUuid &&
    consolidatedGraphData.dataOverrides &&
    'activeScenario' in consolidatedGraphData.dataOverrides &&
    consolidatedGraphData.dataActuals &&
    'activeScenario' in consolidatedGraphData.dataActuals
  ) {
    overrides = consolidatedGraphData.dataOverrides['activeScenario'];
    actuals = consolidatedGraphData.dataActuals['activeScenario'];
  }
  if (
    !activeScenarioUuid &&
    consolidatedGraphData.dataOverrides &&
    'workingModel' in consolidatedGraphData.dataOverrides &&
    consolidatedGraphData.dataActuals &&
    'workingModel' in consolidatedGraphData.dataActuals
  ) {
    overrides = consolidatedGraphData.dataOverrides['workingModel'];
    actuals = consolidatedGraphData.dataActuals['workingModel'];
  }
  if (isBefore(changeDate, date())) {
    const actualExistsIndex = actuals.findIndex((actual) => isSameMonth(actual.date, changeDate));
    if (actualExistsIndex !== -1) {
      actuals[actualExistsIndex].value = value;
    } else {
      actuals.push({ value, date: changeDate });
    }
    return {
      overrides,
      actuals,
    };
  } else {
    const overrideExistsIndex = overrides.findIndex((override) => isSameMonth(override.date, changeDate));
    const roundedValue = roundCalculatedValue({
      value,
      roundingInstructions: consolidatedGraphData.roundingInstructions,
    });
    if (overrideExistsIndex !== -1) {
      overrides[overrideExistsIndex].value = roundedValue;
    } else {
      overrides.push({ value: roundedValue, date: changeDate });
    }
    return {
      overrides,
      actuals,
    };
  }
};
