import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { addYears, endOfYear, format, startOfMonth, subMonths } from 'date-fns';

export interface IUserPreferences {
  primaryOrganizationUuid: string;
  sideMenuExpanded: boolean;
  defaultGraphStartDate: string;
  defaultGraphEndDate: string;
}

export interface UserState {
  uuid: string;
  name: string;
  email: string;
  createdAt?: Date;
  preferences: IUserPreferences;
  role: 'user' | 'support';
  permissions: {
    role: string;
    departmentAccessList: string[];
  };
}

interface IUpdateUserState {
  uuid: string;
  name: string;
  email: string;
  preferences: {
    primaryOrganizationUuid: string;
    sideMenuExpanded: boolean;
    defaultGraphStartDate: string | null;
    defaultGraphEndDate: string | null;
  };
  permissions: {
    role: string;
    departmentAccessList: string[];
  };
  role: 'user' | 'support';
}

const initialState = {
  uuid: '',
  name: '',
  email: '',
  preferences: {
    primaryOrganizationUuid: '',
    sideMenuExpanded: true,
    defaultGraphStartDate: format(subMonths(startOfMonth(new Date()), 3), 'yyyy-MM-dd'),
    defaultGraphEndDate: format(addYears(endOfYear(new Date()), 1), 'yyyy-MM-dd'),
  },
  permissions: {
    role: '',
    departmentAccessList: [],
  },
  role: 'user',
} as UserState;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    update: (state: UserState, action: PayloadAction<IUpdateUserState>) => ({
      ...action.payload,
      preferences: {
        ...state.preferences,
        ...action.payload.preferences,
        defaultGraphStartDate:
          action.payload.preferences.defaultGraphStartDate ?? state.preferences.defaultGraphStartDate,
        defaultGraphEndDate: action.payload.preferences.defaultGraphEndDate ?? state.preferences.defaultGraphEndDate,
      },
    }),
    setPreferences: (state: UserState, action: PayloadAction<IUpdateUserState['preferences']>) => ({
      ...state,
      preferences: {
        ...state.preferences,
        ...action.payload,
        defaultGraphStartDate: action.payload.defaultGraphStartDate ?? state.preferences.defaultGraphStartDate,
        defaultGraphEndDate: action.payload.defaultGraphEndDate ?? state.preferences.defaultGraphEndDate,
      },
    }),
    setPermissions: (state: UserState, action: PayloadAction<IUpdateUserState['permissions']>) => ({
      ...state,
      permissions: {
        ...state.permissions,
        ...action.payload,
      },
    }),
    reset: () => initialState,
  },
});

export const { update, setPreferences, setPermissions, reset } = userSlice.actions;
export default userSlice.reducer;
