import React, { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

const DraggableItem = ({
  id,
  children,
  className,
  liveUpdate = false,
  iconAlwaysVisible = false,
}: {
  id: string;
  children: React.ReactNode;
  className?: string;
  liveUpdate?: boolean;
  iconAlwaysVisible?: boolean;
}): React.ReactNode => {
  const { attributes, listeners, setNodeRef, isOver, isDragging, transform, transition } = useSortable({
    id: id.toString(),
    transition: liveUpdate ? null : undefined,
  });

  const defaultStyle: CSSProperties = {
    borderBottomColor: isOver ? 'green' : '',
    opacity: isDragging ? 0.5 : 1,
  };
  const liveUpdateStyle: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 0, // Set a higher z-index when dragging
  };

  return (
    <div ref={setNodeRef} className={`flex group ${className}`} style={liveUpdate ? liveUpdateStyle : defaultStyle}>
      {/* Drag handle */}
      <div
        className={`flex items-center cursor-grab ${iconAlwaysVisible ? '' : 'group-hover:opacity-100 opacity-0'}`}
        {...attributes}
        {...listeners}
      >
        <EllipsisVerticalIcon className="size-5 text-neutral-200" />
        <EllipsisVerticalIcon className="size-5 -ml-[15px] text-neutral-200" />
      </div>
      {/* Remainder of row to be dragged */}
      {children}
    </div>
  );
};

export default DraggableItem;
