import React, { useMemo, useEffect, useState, useContext } from 'react';
import { IConsolidatedData, IConsolidatedGraphData, IStaticGraphData, IStaticReportData } from '../../entity/types';
import StaticMetricCard from './StaticMetricCard';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import ConsolidatedMetricCard from './ConsolidatedMetricCard';
import { z } from 'zod';
import { ZConsolidatedData, ZStaticReportData } from '../../entity/schemas';
import { IFormattingEnum } from '~/pages/FinancialModelDeprecated/entity/schemas';
import { DashboardPageContext } from '../../context/DashboardContext';
import Skeleton from 'react-loading-skeleton';

const ZMetricProps = z.object({
  metricTitle: z.string(),
  metricValues: z.union([z.array(ZConsolidatedData), z.record(z.string(), ZStaticReportData)]),
  metricFormatting: z.string().optional(),
});

const DashboardMetrics = ({
  staticMetrics,
  consolidatedReports,
}: {
  staticMetrics?: IStaticGraphData;
  consolidatedReports?: IConsolidatedGraphData;
}): React.ReactNode => {
  const { dashboardConfiguration } = useSelector((state: State) => state.organization.configuration);
  const [sectionSize, setSectionSize] = useState(3);
  const { pageLoading } = useContext(DashboardPageContext);

  useEffect(() => {
    const handleResize = (): void => {
      if (window.innerWidth < 600) {
        setSectionSize(1);
      } else if (window.innerWidth < 800) {
        setSectionSize(2);
      } else {
        setSectionSize(3);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const generatedMetrics = useMemo(() => {
    const metricCards: {
      metricTitle: string;
      metricValues: Record<string, IStaticReportData> | IConsolidatedData[];
      formulaUuid: string;
      metricFormatting?: IFormattingEnum | null;
      type: 'static' | 'consolidated';
    }[] = [];
    if (staticMetrics && Object.keys(staticMetrics).length) {
      Object.entries(staticMetrics).map(([key, value]) => {
        metricCards.push({
          metricTitle: value.title,
          metricValues: value.data,
          formulaUuid: key,
          type: 'static',
        });
      });
    }

    if (consolidatedReports && Object.keys(consolidatedReports).length) {
      Object.entries(consolidatedReports)
        .filter(([key]) => dashboardConfiguration.metrics.includes(key))
        .map(([key, value]) => {
          metricCards.push({
            metricTitle: value.title,
            metricValues: value.data,
            metricFormatting: value.formatting,
            formulaUuid: key,
            type: 'consolidated',
          });
        });
    }
    return metricCards;
  }, [staticMetrics, consolidatedReports]);

  const generatedMetricCards = useMemo(() => {
    return generatedMetrics
      .sort(
        (keyA, keyB) =>
          dashboardConfiguration.metrics.indexOf(keyA.formulaUuid) -
          dashboardConfiguration.metrics.indexOf(keyB.formulaUuid),
      )
      .map((metric) => {
        if (metric.type === 'static') {
          return (
            <StaticMetricCard
              key={metric.formulaUuid}
              metricTitle={metric.metricTitle}
              metricValues={metric.metricValues as Record<string, IStaticReportData>}
            />
          );
        } else {
          return (
            <ConsolidatedMetricCard
              key={metric.formulaUuid}
              metricValues={metric.metricValues as IConsolidatedData[]}
              metricTitle={metric.metricTitle}
              metricFormatting={metric.metricFormatting}
            />
          );
        }
      });
  }, [generatedMetrics]);

  const sectionedMetrics: React.ReactElement[][] = useMemo(() => {
    if (generatedMetricCards.length) {
      const sections = [];
      for (let i = 0; i < generatedMetricCards.length; i += sectionSize) {
        sections.push(generatedMetricCards.slice(i, i + sectionSize));
      }
      return sections;
    }
    return [];
  }, [generatedMetricCards, sectionSize]);

  const metricsLoadingSkeleton = (
    <div className="w-full flex flex-col max-w-[1000px] -mb-1">
      <Skeleton height={78} className="w-full rounded-lg -top-1" baseColor="#F8F9F6" />
    </div>
  );

  const showLoadingSkeleton = pageLoading || (!generatedMetrics.length && dashboardConfiguration.metrics.length);

  const returnEmpty = !sectionedMetrics.length && !showLoadingSkeleton;

  let content;
  if (showLoadingSkeleton && !generatedMetrics.length) {
    content = metricsLoadingSkeleton;
  } else if (returnEmpty) {
    content = null;
  } else if (sectionedMetrics.length) {
    content = (
      <div className="w-full max-w-[1000px] flex flex-col gap-3">
        {sectionedMetrics.map((section) => {
          const parsedSectionProps = ZMetricProps.parse(section[0]?.props);
          let gridCols;
          if (section.length === 1) {
            gridCols = 'grid-cols-1';
          } else if (section.length === 2) {
            gridCols = 'grid-cols-2';
          } else if (section.length === 3) {
            gridCols = 'grid-cols-3';
          }
          return (
            <div key={`section-${parsedSectionProps.metricTitle}`} className={`w-full grid gap-3 ${gridCols}`}>
              {section}
            </div>
          );
        })}
      </div>
    );
  } else {
    content = null;
  }

  return content;
};

export default DashboardMetrics;
