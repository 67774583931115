import React from 'react';
import { DndContext, PointerSensor, useSensor, useSensors, DragOverEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Button from '~/components/Button';
import DraggableItem from './DraggableItem';
import { useState } from 'react';
import Typography from '~/components/Typography';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { IFormula } from '~/services/parallel/formulas.types';
import HoverPopover from '~/components/HoverPopover';
import { TrashIcon } from '@heroicons/react/24/outline';
import objectHash from 'object-hash';
import { v4 as uuid } from 'uuid';
import ContentEditableInput from '~/components/Input/ContentEditable';
import { useInput } from '~/components/Input';
import { isEqual } from 'lodash';

interface IProps {
  groups: { name: string; formulas: IFormula[] }[];
  onUpdate: (sortOrder?: { id: string; name: string; sortOrder: string[] }[]) => void;
}

const EditableGroupName = ({
  groupName,
  onUpdate,
}: {
  groupName: string;
  onUpdate: (name: string) => void;
}): React.ReactNode => {
  const [state, setState] = useInput({ value: groupName });

  const handleBlur = (): void => {
    onUpdate(state.value);
  };

  return (
    <ContentEditableInput id={groupName} state={state} setState={setState} onBlur={handleBlur} className="py-[5px]" />
  );
};

const ManageGroups = ({ groups, onUpdate }: IProps): React.ReactNode => {
  const [originalGroups] = useState(
    groups.map((group) => {
      return {
        id: objectHash({ name: group.name, sortOrder: group.formulas.map((formula) => formula.formulaUuid) }),
        name: group.name,
        sortOrder: group.formulas.map((formula) => formula.formulaUuid),
      };
    }),
  );
  const [pendingGroups, setPendingGroups] = useState(originalGroups);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 5,
      },
    }),
  );

  const handleDragOver = (event: DragOverEvent): void => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setPendingGroups((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItems = [...items];
        newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);
        return newItems;
      });
    }
  };

  const handleDeleteGroup = (id: string): void => {
    setPendingGroups((items) => items.filter((item) => item.id !== id));
  };

  const getUniqueGroupName = (groups: { name: string; sortOrder: string[] }[]): string => {
    let newName = 'New Group';
    let counter = 0;
    const groupNames = groups.map((group) => group.name);

    while (groupNames.includes(newName)) {
      counter += 1;
      newName = `New Group (${counter})`;
    }

    return newName;
  };

  const handleUpdateGroupName = (id: string, name: string): void => {
    setPendingGroups((prevState) => {
      const index = prevState.findIndex((item) => item.id === id);
      const newState = [...prevState];
      newState[index].name = name;
      return newState;
    });
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      <Typography>Move groups, rename, etc.</Typography>
      <div className="flex flex-col gap-2">
        <DndContext sensors={sensors} onDragOver={handleDragOver} modifiers={[restrictToVerticalAxis]}>
          <SortableContext items={pendingGroups.map((group) => group.id)} strategy={verticalListSortingStrategy}>
            {pendingGroups.map((group) => {
              const hasAttributes = group.sortOrder.length > 0;
              const DeleteButtonContent = (): React.ReactNode => {
                return (
                  <Button className="!w-auto !px-1" fill="clear" onClick={() => handleDeleteGroup(group.id)}>
                    <TrashIcon
                      className={`size-[18px] ${
                        hasAttributes ? 'text-neutral-50' : 'text-neutral-200 hover/sortableitem:text-red-500'
                      }`}
                    />
                  </Button>
                );
              };
              return (
                <DraggableItem
                  key={group.id}
                  id={group.id}
                  iconAlwaysVisible
                  className="bg-white border border-neutral-50 rounded-md"
                >
                  <div className="flex justify-between items-center w-full py-[3px]">
                    <div className="flex">
                      <EditableGroupName
                        groupName={group.name}
                        onUpdate={(value) => handleUpdateGroupName(group.id, value)}
                      />
                    </div>
                    <div className="pr-2">
                      {!hasAttributes ? (
                        <DeleteButtonContent />
                      ) : (
                        <HoverPopover
                          buttonContent={<DeleteButtonContent />}
                          panelContent={
                            <div className="p-3 bg-white w-[300px] flex flex-col justify-start items-start gap-4">
                              <Typography size="xs">Unable to delete a group unless it has no attributes</Typography>
                            </div>
                          }
                          panelClassName="shadow-lg rounded-xl"
                        />
                      )}
                    </div>
                  </div>
                </DraggableItem>
              );
            })}
          </SortableContext>
        </DndContext>
        <Button
          className="w-full !px-3 text-left !justify-start !bg-green-15 hover:!bg-green-25 text-green border-none"
          onClick={() =>
            setPendingGroups((prevState) => [
              ...prevState,
              { name: getUniqueGroupName(prevState), sortOrder: [], id: uuid() },
            ])
          }
        >
          Add Group
        </Button>
      </div>
      <div className="w-full flex justify-between gap-2">
        <Button className="!w-auto !px-0" fill="clear" onClick={() => onUpdate()}>
          Cancel
        </Button>
        <Button
          className="!w-auto"
          onClick={() => {
            // Only trigger an update if the groups have changed
            if (isEqual(originalGroups, pendingGroups)) {
              onUpdate();
            } else {
              onUpdate(pendingGroups);
            }
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ManageGroups;
