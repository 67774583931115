import React, { useContext, useMemo } from 'react';
import DraggableGraph from '~/components/DraggableGraph';
import { IConsolidatedGraphData } from '../../entity/types';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import Typography from '~/components/Typography';
import { DashboardPageContext } from '../../context/DashboardContext';
import { format, isSameMonth } from 'date-fns';
import date from '~/utils/dates/date';
import Skeleton from 'react-loading-skeleton';

const DashboardLeverGraphs = ({
  consolidatedReports,
}: {
  consolidatedReports?: IConsolidatedGraphData;
}): React.ReactElement => {
  const { dashboardConfiguration } = useSelector((state: State) => state.organization.configuration);
  const { userActiveIndex, setUserActiveIndex, pageLoading } = useContext(DashboardPageContext);
  const leverGraphs = useMemo(() => {
    if (!consolidatedReports) return [];
    return Object.entries(consolidatedReports)
      .filter(([key]) => dashboardConfiguration.levers.includes(key))
      .sort(
        ([keyA], [keyB]) => dashboardConfiguration.levers.indexOf(keyA) - dashboardConfiguration.levers.indexOf(keyB),
      );
  }, [consolidatedReports, dashboardConfiguration]);

  const firstReport = useMemo(() => {
    if (consolidatedReports && Object.keys(consolidatedReports).length) {
      return Object.values(consolidatedReports)[0];
    } else return null;
  }, [consolidatedReports]);

  const currentMonthIndex = useMemo(() => {
    if (firstReport?.data.length) {
      const currentDate = date();
      return firstReport.data.findIndex((item) => isSameMonth(new Date(item.date), currentDate));
    }
    return -1;
  }, [firstReport]);

  const emptyLevers = (
    <div className="flex flex-col w-[320px] justify-center text-center my-10">
      <Typography weight="semibold" color="secondary" className="mb-2">
        Key Levers
      </Typography>
      <Typography color="empty" className="mb-6">
        {`Quickly change key aspects of your model and see the impact across your financial KPI's`}
      </Typography>
      <Typography color="empty">Add these in by editing the dashboard</Typography>
    </div>
  );

  const leversLoadingSkeleton = (
    <div className="w-full flex flex-col gap-2 max-w-[1000px] -mt-1">
      <Skeleton className={'max-h-[212px] min-h-[210px] !h-[18dvw] w-full rounded-lg'} baseColor="#F8F9F6" />
      <Skeleton className={'max-h-[212px] min-h-[210px] !h-[18dvw] w-full rounded-lg'} baseColor="#F8F9F6" />
      <Skeleton className={'max-h-[212px] min-h-[210px] !h-[18dvw] w-full rounded-lg'} baseColor="#F8F9F6" />
    </div>
  );

  const showLoadingSkeleton = pageLoading || Boolean(!leverGraphs.length && dashboardConfiguration.levers.length);

  const showEmptyLevers = !leverGraphs.length && !showLoadingSkeleton;

  let content;
  if (showLoadingSkeleton && !leverGraphs.length) {
    content = leversLoadingSkeleton;
  } else if (showEmptyLevers) {
    content = emptyLevers;
  } else if (leverGraphs.length) {
    content = leverGraphs.map(([key, value]) => {
      return (
        <DraggableGraph
          key={key}
          consolidatedGraphData={value}
          formulaUuid={key}
          setExternalActiveIndex={setUserActiveIndex}
          card={{
            title: value.title,
            workingModel:
              userActiveIndex !== -1 || currentMonthIndex !== -1
                ? value.data[userActiveIndex !== -1 ? userActiveIndex : currentMonthIndex]?.workingModel?.toString() ??
                  '0'
                : undefined,
            activeScenario:
              userActiveIndex !== -1 || currentMonthIndex !== -1
                ? value.data[userActiveIndex !== -1 ? userActiveIndex : currentMonthIndex]?.activeScenario?.toString()
                : undefined,
            month:
              userActiveIndex !== -1 || currentMonthIndex !== -1
                ? format(
                    new Date(
                      value.data[userActiveIndex !== -1 ? userActiveIndex : currentMonthIndex]?.date ?? new Date(),
                    ),
                    'MMM',
                  ).toUpperCase()
                : undefined,
          }}
          externalActiveIndex={userActiveIndex}
        />
      );
    });
  } else {
    content = emptyLevers;
  }

  return <div className="flex flex-col gap-3 w-full items-center justify-center">{content}</div>;
};

export default DashboardLeverGraphs;
