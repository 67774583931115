import { z } from 'zod';
import request from '~/utils/request';
import { IIntegration, IIntegrationMapping, ZIntegration, ZIntegrationMapping } from './integrations.types';
import { IAPIResponse } from '~/utils/types';

export const list = async ({ organizationUuid }: { organizationUuid: string }): Promise<IIntegration[]> => {
  const integrations = (await request({
    url: `/integrations`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
  })) as IAPIResponse;

  return z.array(ZIntegration).parse(integrations.data.data);
};

export const listMappings = async ({
  organizationUuid,
  integrationUuid,
}: {
  organizationUuid: string;
  integrationUuid: string;
}): Promise<IIntegrationMapping[]> => {
  const mappings = (await request({
    url: `/integrations/${integrationUuid}/mappings`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
  })) as IAPIResponse;

  return z.array(ZIntegrationMapping).parse(mappings.data.data);
};
