import { z } from 'zod';
import iso8601Validator from '~/utils/zodValidationHelpers/iso8601Validator';

/**
 * FORMULA: START
 */
export enum ICalculationTypeEnum {
  NewHireNumber = 'newHireNumber',
  HeadcountNumber = 'headcountNumber',
  SalariesAndWages = 'salariesAndWages',
  SoftwareExpenses = 'softwareExpenses',
  HeadcountExpenses = 'headcountExpenses',
  OtherExpenses = 'otherExpenses',
}

export enum IVariableTypeEnum {
  Constant = 'constant',
  Formula = 'formula',
  Self = 'self',
  Calculated = 'calculated',
}

export enum ITimeModifierFunctionEnum {
  Previous = 'previous',
  Average = 'average',
}

export enum IRoundDirectionEnum {
  Up = 'up',
  Down = 'down',
  Nearest = 'nearest',
}

export enum IFormattingEnum {
  Number = 'number',
  Currency = 'currency',
  Percent = 'percent',
}

export enum IFormulaTypeEnum {
  ModelBuilder = 'modelBuilder',
  ContractCashCollection = 'contractCashCollection',
  ContractRevenueRecognition = 'contractRevRecognition',
  ContractSetupFee = 'contractSetupFee',
}

export const ZFormula = z.object({
  uuid: z.string(),
  formulaUuid: z.string(),
  organizationUuid: z.string().uuid(),
  scenarioUuid: z.string().nullable(),
  createdBy: z.string().nullable(),
  deletedBy: z.string().nullable(),
  createdAt: iso8601Validator,
  deletedAt: iso8601Validator.nullable(),
  planDate: iso8601Validator.optional(),
  isProtected: z.boolean(),
  recipe: z.object({
    name: z.string(),
    expression: z.string(),
    variables: z.record(
      z.string().regex(/^\$\d+$/), // Keys expected to be $1, $2, $3, etc.
      z.object({
        type: z.nativeEnum(IVariableTypeEnum),
        formulaUuid: z.string().nullable(),
        constantValue: z.number().optional().nullable(),
        timeModifier: z
          .object({
            function: z.nativeEnum(ITimeModifierFunctionEnum),
            period: z.number(),
          })
          .partial(),
        calculationType: z.nativeEnum(ICalculationTypeEnum).nullable(),
        calculationModifier: z
          .object({
            jobTitle: z.string().optional(),
          })
          .optional(),
      }),
    ),
    roundingInstructions: z
      .object({
        direction: z.nativeEnum(IRoundDirectionEnum),
        precision: z.number(),
      })
      .nullable()
      .optional(),
    relatedResourceUuid: z.string().uuid().optional(),
  }),
  overrides: z.array(
    z.object({
      date: iso8601Validator,
      value: z.number(),
    }),
  ),
  actuals: z
    .array(
      z.object({
        date: iso8601Validator,
        value: z.number(),
      }),
    )
    .optional(),
  dataSourceUuid: z.string().nullable(),
  formatting: z.nativeEnum(IFormattingEnum).nullable().optional(),
  type: z.nativeEnum(IFormulaTypeEnum),
  calculations: z.array(
    z.object({
      date: iso8601Validator,
      value: z.number().nullable(),
    }),
  ),
});

export type IFormula = z.infer<typeof ZFormula>;

const ZSortOrder = z.array(z.string());
/**
 * FORMULA: END
 */

/**
 * SORT ORDER: START
 */
const ZFormulaGroupSorting = z.object({ name: z.string(), sortOrder: ZSortOrder });

export type IFormulaGroupSorting = z.infer<typeof ZFormulaGroupSorting>;

export const ZFormulaSort = z.object({
  uuid: z.string(),
  createdAt: iso8601Validator,
  updatedAt: iso8601Validator,
  organizationUuid: z.string().uuid(),
  scenarioUuid: z.string().nullable(),
  groups: z.array(ZFormulaGroupSorting),
});

export type IFormulaSort = z.infer<typeof ZFormulaSort>;
/**
 * SORT ORDER: END
 */
